<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="选择职位"
                    >
                        <a-select
                            placeholder="请选择要登记的职位"
                            v-decorator="['jid', {rules: [{ required: true, message: '请选择要登记的职位' }]}]"
                        >
                            <a-select-option v-for="(item, key) in config.job" :key="item.jid" :value="item.jid">{{ item.jobname }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                        label="姓名"
                        v-if="show"
                    >
                        <a-input placeholder="请输入人才姓名"
                                 v-decorator="['empname', {rules: [{ required: true, max: 16, message: '请输入人才姓名, 最大16个字符' }], initialValue: ''}]"/>
                    </a-form-item>

                    <a-form-item
                        label="手机"
                        v-if="show"
                    >
                        <a-input placeholder="请输入手机号"
                                 v-decorator="['mobile', {rules: [{ required: true, validator: requiredMobile, message: '请输入人才的手机号, 最大11个字符' }], initialValue: ''}]"/>
                    </a-form-item>

                    <a-form-item
                        label="身份证"
                        v-if="show"
                    >
                        <a-input placeholder="请输入人才的身份证号"
                                 v-decorator="['idcard', {rules: [{ required: false, validator: checkIdcard, message: '请输入人才的身份证号' }], initialValue: ''}]"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Array,
            default: [],
        },
        rcname: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '登记员工',
            form: this.$form.createForm(this),
            config: {},
            data: {},
            show: false,
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
                this.show = false;
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
            this.show = this.id.length == 1 ? true : false;
            this.title = '登记员工 ' + this.rcname;

            // 初始化底部宽度
            this.$nextTick(() => {
                let data = {};
                data.id = this.id[0];

                this.http('/manage/rcjob/info', data, (res) => {
                    this.config = res.config;
                    this.data = res.data;

                    if (this.id.length == 1) {
                        this.form.setFieldsValue({
                            empname: res.data.rcname,
                            mobile: res.data.mobile,
                            idcard: res.data.idcard,
                        });
                    }

                }, null, 1);

            });
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/rcjob/checkin';
                        let data = values;

                        data.id = this.id;

                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')
                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
