<template>
    <a-modal
        title="人才标签"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
        okText="搜索"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form class="inline-form" :form="form">

                    <a-form-item
                        label="标签名"
                    >
                        <a-checkable-tag v-for="(item, key) in tags" :key="item.tagid"
                                         :checked="selectedTags.indexOf(item.tagid) > -1"
                                         @change="checked => tagAdd(item.tagid, checked)"
                        >{{ item.tagname }}</a-checkable-tag>
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        checkData: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            tags: {},
            selectedTags: [],
        };
    },
    watch: {
        // 实现双向绑定
        visible(val) {
            if (val === false) {
                this.form.resetFields(); // 重置表单
            } else {
                this.getData();
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible'
    },
    methods: {
        tagAdd (tagid, checked) {
            // console.log(checked);
            const { selectedTags } = this;
            const nextSelectedTags = checked
                ? [...selectedTags, tagid]
                : selectedTags.filter(t => t !== tagid);
            //console.log('You are interested in: ', nextSelectedTags);

            this.selectedTags = nextSelectedTags;
            //console.log(this.selectedTags)
        },
        closeModal() {
            this.$emit('changeVisible', false);
        },
        getData() {
            let data = {}
            data.rid = this.rid

            this.http('/manage/rctag/hots', data, (res) => {
                this.tags = res.list;

            }, null, 1)
        },
        okFun() {
            let params = {}
            for (var i in this.checkData) {
                params[i] = this.checkData[i]
            }

            params.tags = this.selectedTags;

            this.$emit('search', params);
            this.closeModal();
        },
        cancelFun() {
            this.closeModal();
        },
    }
}
</script>
