<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-form-add" style="padding: 40px;">
            <div style="padding: 0 80px;">
                <search-screen
                    :checkData="postData"
                    :set-condition="{
                    pagesize: setCondition.pagesizes,
                    field: setCondition.fields,
                }"
                    :scenario="scenario"
                    @setOption="setOption"
                    @clear="clear"
                    @search="search"
                >
                </search-screen>
            </div>

            <div style="padding: 60px;">
                <div id="mainAdd" style="width: 100%;height:400px;"></div>
            </div>

            <div style="padding: 60px;">
                <a-row style="text-align: center">
                    <a-col :span="12">
                        <div id="mainVisit" style="width: 100%;height:400px;"></div>
                    </a-col>

                    <a-col :span="12">
                        <div id="mainInterview" style="width: 100%;height:400px;"></div>
                    </a-col>
                </a-row>
            </div>

            <div style="padding: 60px;">
                <a-row style="text-align: center">
                    <a-col :span="12">
                        <div id="mainJob" style="width: 100%;height:400px;"></div>
                    </a-col>

                    <a-col :span="12">
                        <div id="mainCheckin" style="width: 100%;height:400px;"></div>
                    </a-col>
                </a-row>
            </div>


        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';
import searchScreen from '@/components/list/searchScreen';

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {PieChart, BarChart, LineChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    LegendComponent,
    ToolboxComponent,
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    PieChart,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent
]);

export default {
    components: {
        listHeader,
        searchScreen,
    },
    data() {
        return {
            title: '数据分析',
            scenario: 'rcanalysisFenxi2',
            postData: {
                sdate: '',
                edate: '',
                type: 'fenxi2',
            },
            xdate: [],
            setCondition: {
                fields: [],// 显示字段
                pagesizes: {},// 每页显示数筛选条件
            },
        }
    },
    created() {
        this.$store.state.showLayoutMenu = false;
        this.getData();
    },
    mounted() {
    },
    methods: {
        getData() {
            this.http('/manage/rcanalysis/fenxi', this.postData, (res) => {
                this.xdate = res.list.xAxis;

                this.chart(res.list.add);
                this.chart(res.list.visit);
                this.chart(res.list.interview);
                this.chart(res.list.job);
                this.chart(res.list.checkin);

            }, null, 2);
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                case 'fenxi':
                    this.$router.push('/rcanalysis/fenxi');
                    break;
                case 'fenxi2':
                    this.$router.push('/rcanalysis/fenxi2');
                    break;
                default:
                    break;
            }
        },
        chart(data) {
            let chartDom = document.getElementById(data.main);
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                title: {
                    text: data.title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: data.title,
                        type: 'pie',
                        radius: '50%',
                        data: data.data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    }
}
</script>

<style lang="less" src="../../assets/css/center/center.less"></style>