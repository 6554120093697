<template>
    <div class="wrap">
        <div class="job-detail-column">

            <a-tabs
                v-model:activeKey="viewtype"
                @change="changeTab"
                style="padding:20px 60px 0 60px; text-align: right"
            >
                <a-tab-pane key="rencai" tab="人才数据" v-if="check_limit('rencai_save')"></a-tab-pane>
                <a-tab-pane key="employee" tab="员工数据" v-if="this.check_limit('employee_import')"></a-tab-pane>
                <a-tab-pane key="caiwu" tab="财务数据" v-if="check_limit('statement_save')"></a-tab-pane>
            </a-tabs>

            <div class="center-wrap" style="padding-left: 20px" v-if="viewtype == 'rencai'">
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4" class="col">
                        <p class="title">总人才数</p>
                        <p class="content">
                            <a href="javascript:;" title="全库人才" @click="actionTo('/rencai/list')">{{ rencai.rcall }}</a>
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">本月新增</p>
                        <p class="content">
                            {{ rencai.monthall }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待处理回访</p>
                        <p class="content">
                            {{ rencai.visitall }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待处理面试</p>
                        <p class="content">
                            {{ rencai.interviewall }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待处理入职</p>
                        <p class="content">
                            {{ rencai.joball }}
                        </p>
                    </a-col>
                </a-row>


                <div style="padding: 60px;">
                    <a-row style="text-align: center">
                        <a-col :span="12">
                            <div id="dayrencai" style="width: 100%;height:400px;"></div>
                        </a-col>

                        <a-col :span="12">
                            <div id="dayinterview" style="width: 100%;height:400px;"></div>
                        </a-col>
                    </a-row>

                    <a-row style="text-align: center">
                        <a-col :span="12">
                            <div id="dayjob" style="width: 100%;height:400px;"></div>
                        </a-col>

                        <a-col :span="12">
                            <div id="daycheckin" style="width: 100%;height:400px;"></div>
                        </a-col>
                    </a-row>
                </div>
            </div>

            <div class="center-wrap" style="padding-left: 20px" v-if="viewtype == 'employee'">
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4" class="col">
                        <p class="title">全部员工</p>
                        <p class="content">
                            <a href="javascript:;" title="在职员工" @click="actionTo('/employee/list')">{{ employee.employeejob }}</a>
                            /
                            {{ employee.employeeall }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">本月入职</p>
                        <p class="content">
                            {{ employee.monthnew }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">本月离职</p>
                        <p class="content">
                            {{ employee.monthquit }}
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">合同提醒</p>
                        <p class="content">
                            <a href="javascript:;" class="red" @click="actionTo('/empcontract/list-remind')">{{ employee.contract }}</a>
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">政策提醒</p>
                        <p class="content">
                            <a href="javascript:;" class="red" @click="actionTo('/emppolicy/list-remind')">{{ employee.policy }}</a>
                        </p>
                    </a-col>
                </a-row>


                <div style="padding: 60px;">
                    <a-row style="text-align: center">
                        <a-col :span="12">
                            <div id="cpjob" style="width: 100%;height:400px;"></div>
                        </a-col>

                        <a-col :span="12">
                            <div id="cpmonth" style="width: 100%;height:400px;"></div>
                        </a-col>
                    </a-row>
                </div>
            </div>

            <div class="center-wrap" style="padding-left: 20px" v-if="viewtype == 'caiwu'">
                <a-row type="flex" justify="space-around" align="middle">
                    <a-col :span="4" class="col">
                        <p class="title">总盈收</p>
                        <p class="content">
                            <a href="javascript:;" @click="actionTo('/statement/list')">{{ caiwu.money }}</a>
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待对账单</p>
                        <p class="content">
                            <a href="javascript:;" class="red" @click="actionTo('/bill/list')">{{ caiwu.billnum }}</a>
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待收款</p>
                        <p class="content">
                            <a href="javascript:;" @click="actionTo('/statement/list')">{{ caiwu.smoney }}</a>
                        </p>
                    </a-col>
                    <a-col :span="4" class="col">
                        <p class="title">待开票</p>
                        <p class="content">
                            <a href="javascript:;" @click="actionTo('/statement/list')">{{ caiwu.kmoney }}</a>
                        </p>
                    </a-col>
                </a-row>


                <div style="padding: 60px;">
                    <a-row style="text-align: center">
                        <a-col :span="12">
                            <div id="statement" style="width: 100%;height:400px;"></div>
                        </a-col>

                        <a-col :span="12">
                            <div id="bill" style="width: 100%;height:400px;"></div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import {PieChart, BarChart, LineChart} from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    LegendComponent,
    ToolboxComponent,
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {LabelLayout, UniversalTransition} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {CanvasRenderer} from 'echarts/renderers';
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    TransformComponent,
    PieChart,
    BarChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    ToolboxComponent
]);

export default {
    components: {
        listHeader,
    },
    data() {
        return {
            scenario: 'centerPing',
            rencai: {},
            employee: {},
            caiwu: {},
            viewtype: '',
        }
    },
    created() {
        this.$store.state.showLayoutMenu = false;

        if (this.check_limit('statement_save')) {
            this.viewtype = 'caiwu';
        } else if (this.check_limit('employee_import')) {
            this.viewtype = 'employee';
        } else if (this.check_limit('rencai_save')) {
            this.viewtype = 'rencai';
        }

        this.getData();
    },
    methods: {
        changeTab(e) {
            this.getData();
        },
        getData() {

            this.http('/manage/center/ping', {viewtype: this.viewtype}, (res) => {
                switch (this.viewtype) {
                    case 'rencai':
                        this.rencai = res.data;

                        this.chart(res.data.dayrencai);
                        this.chart(res.data.dayinterview);
                        this.chart(res.data.dayjob);
                        this.chart(res.data.daycheckin);
                        break;
                    case 'employee':
                        this.employee = res.data;

                        this.chart(res.data.cpjob);
                        this.chart(res.data.cpmonth);
                        break;
                    case 'caiwu':
                        this.caiwu = res.data;

                        this.chart(res.data.statement);
                        this.chart(res.data.bill);
                        break;
                    default:
                        break;
                }

                setTimeout(this.getData, 30000);

            }, null, 2);
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                default:
                    break;
            }
        },
        actionTo(url) {
            this.$router.push(url);
        },
        chart(data) {
            let chartDom = document.getElementById(data.main);
            let myChart = echarts.init(chartDom);
            let option;

            option = {
                title: {
                    text: data.title,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: data.title,
                        type: 'pie',
                        radius: '50%',
                        data: data.data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            option && myChart.setOption(option);
        }
    }
}
</script>

<style lang="less" src="../../assets/css/center/center.less"></style>
<style>
.center-wrap .col {
    border: 1px #ccc solid;
}
.center-wrap .title {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
    background-color: #1B8392;
    color: #fff;
}
.center-wrap .content {
    font-size: 18px;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
}
.center-wrap .content a {
    font-size: 18px;
}
.center-wrap .content .red {
    color: red;
    font-size: 22px;
}
</style>
