import layout from '@/components/common/layout';
import empPolicyList from '../views/emppolicy/list';
import empPolicyEmployee from '../views/emppolicy/employee';
import empPolicyRemind from '../views/emppolicy/remindlist';

export default [
    {
        path: '/emppolicy',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "政策管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'emppolicy_list',// 子选项
                    }
                },
                component: empPolicyList,
            },
            {
                path: 'list-policy',
                meta: {
                    metaInfo: {
                        title: "员工政策",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'employee_list',// 子选项
                    }
                },
                component: empPolicyEmployee,
            },
            {
                path: 'list-remind',
                meta: {
                    metaInfo: {
                        title: "政策提醒",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'emppolicy_remind',// 子选项
                    }
                },
                component: empPolicyRemind,
            },
        ]
    },
];
