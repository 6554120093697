<template>
  <div class="wrap">
    <listHeader :title="postData.id > 0 ? '编辑新闻' : '发布新闻'"></listHeader>

    <div class="wrap-form-add">
      <div class="wrap-form-list">
        <div class="wrap-column-title flex align-items"><span class="line"></span>
          <h3>新闻</h3></div>
        <div class="wrap-form-info">
          <a-row>
            <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="20">
              <a-form :form="form">

                <a-form-item
                  label="新闻分类"
                  :colon=false
                  v-bind="formItemLayout"
                  required
                >
                  <a-radio-group
                    v-decorator="['ncid', {rules: [{ required: true, message: '请选择新闻分类' }], initialValue: ''}]">
                    <a-radio v-for="(item, key) in config.ncid" :key="item.ncid" :value="item.ncid">
                      {{ item.ncname }}
                    </a-radio>
                  </a-radio-group>
                </a-form-item>

                <a-form-item
                  label="标题"
                  :colon=false
                  v-bind="formItemLayout"
                  required
                >
                  <a-input placeholder="请输入新闻标题"
                           v-decorator="['title', {rules: [{ required: true, max: 64, message: '请输入新闻标题' }], initialValue: ''}]"/>
                </a-form-item>

                <a-form-item
                  label="封面图片"
                  :colon=false
                  v-bind="formItemLayout"
                  required
                >
                  <a-upload
                    name="avatar"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="uploadImg"
                  >
                    <img v-if="visible" :src="data.imageUrl" alt="avatar" />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">
                        选择图片
                      </div>
                    </div>
                  </a-upload>
                </a-form-item>

                <a-form-item
                  label="描述"
                  :colon=false
                  v-bind="formItemLayout"
                >
                  <a-textarea
                    placeholder="请输入新闻描述"
                    v-decorator="['description', {rules: [{ max: 128, message: '新闻描述不能超过128个字符' }], initialValue: ''}]"
                    :auto-size="{ minRows: 2 }"
                  />
                </a-form-item>

                <a-form-item
                  label="内容"
                  :colon=false
                  v-bind="formItemLayout"
                  required
                >
                  <ckeditor :editor="editor" v-model="data.content" :config="editorConfig"
                            @ready="onReady"></ckeditor>
                </a-form-item>

                <a-form-item
                  label="转载地址"
                  :colon=false
                  v-bind="formItemLayout"
                >
                  <a-input placeholder="请输入转载地址"
                           v-decorator="['fromurl', {rules: [{ max: 128, message: '转载地址不能超过128个字符' }], initialValue: ''}]"/>
                </a-form-item>

                <a-form-item
                  v-bind="formBtnLayout"
                >
                  <a-button type="primary" @click="create">确定发布</a-button>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import listHeader from '@/components/list/listHeader'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
    import '@ckeditor/ckeditor5-build-classic/build/translations/zh-cn'

    export default {
        components: {
            listHeader,
        },
        data () {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    removePlugins: ['MediaEmbed'], //除去视频按钮
                    language: 'zh-cn',//中文包
                    ckfinder: {
                        'uploaded': 1,
                        'url': '/',
                        // 后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
                    },
                    toolbar: [
                        'heading',//段落
                        '|',//分隔
                        'bold',//加粗
                        'italic',//倾斜
                        'link', //超链接
                        'bulletedList', //项目列表
                        'numberedList', //编号列表
                        'blockQuote',//块引用
                        'undo', //撤销
                        'redo',//重做
                        'imageStyle:full', //图片通栏显示
                        'imageStyle:side', //图片侧边显示
                        'imageTextAlternative',//更换图片替换文本
                        'imageUpload',//插入图像
                    ],//工具栏显示
                },
                content: '',
                imageList: [],
                visible: false, // 是否显示图片src
                loading: false, // 显示图片上传中
                formItemLayout: {
                    labelCol: {
                        span: 5,
                    },
                    wrapperCol: {
                        span: 19,
                    },
                },
                formBtnLayout: {
                    wrapperCol: {
                        span: 19,
                        offset: 5,
                    },
                },
                form: this.$form.createForm(this),
                postData: {
                    'newsid': 0,
                },
                data: {},
                config: {},
                dateFormat: 'YYYY-MM-DD',
            }
        },
        Watch: {},
        created () {
            this.setData()
            this.getData()
        },
        methods: {
            setData () {
                for (let i in this.postData) {
                    if (this.$route.query[i] === undefined) {
                        continue
                    }
                    this.postData[i] = this.$route.query[i]
                }
            },
            getData () {
                // 查询数据
                this.http('/manage/news/info', this.postData, (res) => {
                    this.data = res.data
                    this.config = res.config

                    if (res.data.newsid) {
                        this.data.image = res.data.image
                        this.data.imageStatus = 1
                        this.visible = true

                        this.form.setFieldsValue({
                            title: res.data.title,
                            ncid: res.data.ncid,
                            description: res.data.description,
                            fromurl: res.data.fromurl
                        })
                    } else {
                        // 默认类型
                        for (let i in res.config.ncid) {
                            this.form.setFieldsValue({ ncid: res.config.ncid[i].ncid })
                            break
                        }
                    }

                }, null, 2)
            },
            uploadImg (e) {
                this.visible = false;
                this.loading = true;

                let that = this;
                let reader = new FileReader();
                reader.readAsDataURL(e.file);

                //FileReader的onload句柄绑定回调函数
                reader.onload = function () {
                    let filedata = this.result.split(',')[1];
                    let data = {
                        site: 'hellohro',
                        module: 'news',
                        filestatus: 0,
                        min: '400,240',
                        filedata: {
                            'tmp_name': filedata,
                            'type': e.file.type,
                            'name': e.file.name,
                            'size': e.file.size,
                        },
                    };

                    that.httpImg('', data, (res) => {
                        that.data.image = res.data.file;
                        that.data.imageUrl = res.data.url + '/min/1';
                        that.data.imageStatus = 0;
                        that.visible = true;
                        that.loading = false;
                    });
                }
            },
            create () {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {
                            // 没有上传图片
                            if (!this.data.image) {
                                this.alert({ content: '请上传广告图片' })
                                return false
                            }

                            let url = ''
                            if (this.postData.newsid > 0) {
                                values.newsid = this.postData.newsid
                                url = '/manage/news/edit'
                            }
                            else {
                                url = '/manage/news/save'
                            }
                            values.image = this.data.image
                            values.imageStatus = this.data.imageStatus
                            values.content = this.data.content
                            values.imageList = this.imageList

                            this.http(url, values, (res) => {
                                this.toast({ content: '操作成功' })
                                this.$router.go(-1)
                            }, null, 1)
                        }
                    },
                )
            },
            onReady (editor) {
                // Insert the toolbar before the editable area.
                editor.ui.getEditableElement().parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement(),
                )
                editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                    //let val = editor.getData();
                    return {
                        upload: async () => {
                            return await loader.file.then(file => {
                                const reader = new FileReader()
                                // F.readAsArrayBuffer(f);
                                reader.readAsDataURL(file)
                                return new Promise(resolve => {
                                    reader.onload = function () {
                                        resolve({ readAsDataURL: reader.result, file: file })
                                    }
                                })
                            }).then(result => {
                                // 执行上传上传
                                let that = this

                                let filedata = result.readAsDataURL.split(',')[1]
                                let data = {
                                    site: 'hellohro',
                                    module: 'news',
                                    filestatus: 0,
                                    filedata: {
                                        'tmp_name': filedata,
                                        'type': result.file.type.split('/')[1],
                                        'name': result.file.name,
                                        'size': result.file.size,
                                    },
                                }

                                return new Promise(resolve => {
                                    that.httpImg('', data, (res) => {
                                        that.imageList.push(res.data.file)
                                        resolve({
                                            default: res.data.url,
                                        })
                                    })
                                })
                            })

                        },
                    }
                }
            },
        },
    }
</script>

<style>
  .ck-editor__editable {
    min-height: 200px;
  }
</style>
<style lang="less" src="../../assets/css/add/add.less"></style>
