// 404错误模块，相关路由规则
import error404 from '../views/error/404';
// import network from '@/components/error/network';
// import noauthority from '@/components/error/noauthority';

export default [
    {
        path: '/404',
        meta: {
            title: '页面不存在',
        },
        component: error404
    },
    /*{
        path: '/network',
        meta: {
            title: '网络不给力',
            requiresAuth: false
        },
        component: network
    },
    {
        path: '/noauthority',
        meta: {
            title: '无访问权限',
            requiresAuth: false
        },
        component: noauthority
    },*/
    {
        path: '*',
        redirect: '/404'
    }
]