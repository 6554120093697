<template>
    <div class="wrap">
        <listHeader :title="title"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="类型"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-select
                                        placeholder="请选择表单类型"
                                        v-decorator="['type', {rules: [{ required: true, message: '请选择表单类型' }]}]"
                                    >
                                        <a-select-option v-for="(item, key) in config.type" :key="key" :value="key">
                                            {{item}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="表单名称"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入表单名称"
                                             v-decorator="['finame', {rules: [{ required: true, max: 32, message: '请输入表单名称, 最大32个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="参数"
                                    v-bind="formItemLayout"
                                >
                                    <a-textarea
                                        v-decorator="['arg', {rules: [{ required: false, max: 1024, message: '参数不超过1024个字符' }], initialValue: ''}]"
                                        placeholder="选择、单选、多选时用。参数用、分隔。最大1024字符"
                                        :auto-size="{ minRows: 3 }"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="必填"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-radio-group v-decorator="['required', {rules: [{ required: true, message: '请选择是否必填' }]}]">
                                        <a-radio v-for="(item, key) in config.required" :key="key" :value="key">{{ item }}</a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="排序"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入排序值，默认100"
                                             v-decorator="['ordernum', {rules: [{ required: false, message: '请输入排序值，默认100' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item v-bind="formBtnLayout">
                                    <a-button type="primary" @click="create">保存</a-button>
                                    <a-button @click="$router.go(-1)" style="margin-left:10px;">返回</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
import moment from 'moment'

export default {
    components: {
        listHeader,
    },
    data () {
        return {
            title: '',
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                id: '',
                jid: '',
            },
            data: {},
            config: {},
        }
    },
    Watch: {},
    created () {
        this.setData()


        this.title = this.postData.id ? '编辑表单' : '新增表单';
        this.getData()
    },
    methods: {
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue;
                }
                this.postData[i] = this.$route.query[i];
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/jobtpl/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

                this.form.setFieldsValue({
                    type: res.data.type,
                    finame: res.data.finame,
                    arg: res.data.arg,
                    required: res.data.required,
                    ordernum: res.data.ordernum,
                });

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url;

                        if (this.postData.id) {
                            url = '/manage/jobtpl/edit';
                            values.id = this.postData.id;
                        } else {
                            url = '/manage/jobtpl/save';
                            values.jid = this.postData.jid;
                        }

                        this.http(url, values, (res) => {
                            this.toast({ content: '操作成功' }, () => {});
                            this.$router.go(-1);
                        }, null, 1);
                    }
                },
            )
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
