<template>
    <div class="wrap">
        <listHeader :title="postData.id > 0 ? '编辑企业' : '新增企业'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-column-title flex align-items"><span class="line"></span>
                    <h3>企业信息</h3></div>
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="企业分类"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-radio-group
                                        v-decorator="['qcid', {rules: [{ required: true, message: '请选择企业分类' }], initialValue: ''}]">
                                        <a-radio v-for="(item, key) in config.qcid" :key="item.qcid" :value="item.qcid">
                                            {{ item.qcname }}
                                        </a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="企业Logo"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-upload
                                        name="avatar"
                                        list-type="picture-card"
                                        class="avatar-uploader"
                                        :show-upload-list="false"
                                        :customRequest="uploadImg"
                                    >
                                        <img v-if="visible" :src="data.imageUrl" alt="avatar"/>
                                        <div v-else>
                                            <a-icon :type="loading ? 'loading' : 'plus'"/>
                                            <div class="ant-upload-text">
                                                选择图片
                                            </div>
                                        </div>
                                    </a-upload>
                                </a-form-item>

                                <a-form-item
                                    label="企业名称"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入企业名称"
                                             v-decorator="['qname', {rules: [{ required: true, max: 64, message: '请输入企业名称, 最大64个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="排序"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请填写排序值 0~100"
                                             v-decorator="['ordernum', {rules: [{ required: true, validator: checkOrderNUM, message: '请填写排序值 0~100' }]} ]"/>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listHeader
    },
    data() {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 19
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                'qid': 0,
            },
            data: {},
            config: {},
            dateFormat: 'YYYY-MM-DD',
        }
    },
    Watch: {},
    created() {
        this.setData();
        this.getData();
    },
    methods: {
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) continue;
                this.postData[i] = this.$route.query[i];
            }
        },
        getData() {
            // 初始化批量操作
            this.selectedRowKeys = [];

            // 查询数据
            this.http('/manage/qiye/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

                if (res.data.qid) {
                    this.data.image = res.data.image;
                    this.data.imageStatus = 1;
                    this.visible = true;

                    this.form.setFieldsValue({
                        qcid: res.data.qcid,
                        qname: res.data.qname,
                        ordernum: res.data.ordernum,
                    });
                } else {
                    // 默认类型
                    for (let i in res.config.qcid) {
                        this.form.setFieldsValue({qcid: i});
                        break;
                    }
                }

            }, null, 2);
        },
        uploadImg(e) {
            this.visible = false;
            this.loading = true;

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(e.file);

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {
                let filedata = this.result.split(',')[1];
                let data = {
                    site: 'hellohro',
                    module: 'qiye',
                    filestatus: 0,
                    min: '400,240',
                    filedata: {
                        'tmp_name': filedata,
                        'type': e.file.type,
                        'name': e.file.name,
                        'size': e.file.size,
                    },
                };

                that.httpImg('', data, (res) => {
                    that.data.image = res.data.file;
                    that.data.imageUrl = res.data.url + '/min/1';
                    that.data.imageStatus = 0;
                    that.visible = true;
                    that.loading = true;
                });
            };
        },
        create() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {
                        // 没有上传图片
                        if (!this.data.image) {
                            this.alert({content: '请上传企业图片'});
                            return false;
                        }

                        let url = '';
                        if (this.postData.qid > 0) {
                            values.qid = this.postData.qid;
                            url = '/manage/qiye/edit';
                        } else {
                            url = '/manage/qiye/save';
                        }
                        values.image = this.data.image;
                        values.imageStatus = this.data.imageStatus;

                        this.http(url, values, (res) => {
                            this.toast({content: '操作成功'});
                            this.$router.go(-1);
                        }, null, 1);
                    }
                }
            );
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
