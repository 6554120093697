<template>
    <div class="wrap">
        <listHeader title="新增人才"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="姓名"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入人才姓名"
                                             v-decorator="['rcname', {rules: [{ required: true, max: 16, message: '请输入人才姓名, 最大16个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="联系方式"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入人才的手机号或微信号"
                                             v-decorator="['contact', {rules: [{ required: true, max: 64, message: '请输入人才的手机号或微信号, 最大64个字符' }], initialValue: ''}]"/>
                                </a-form-item>


                                <a-form-item
                                    label="供应商"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-auto-complete
                                        class="global-search"
                                        size="large"
                                        style="width: 100%"
                                        placeholder="请输入供应商名称关键词"
                                        option-label-prop="title"
                                        @select="autoSelect"
                                        @focus="autoSearch"
                                        @search="autoSearch"
                                        v-decorator="['supid', {rules: [{ required: false, message: '请选择供应商' }]}]"
                                    >
                                        <template slot="dataSource">
                                            <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                                没有找到供应商，去<a href="/supplier/create" target="_blank">新增供应商</a>
                                            </a-select-option>
                                            <a-select-option v-else v-for="item in dataSource" :key="item.supid"
                                                             :title="item.supname">
                                                {{ item.supname }}
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </a-form-item>

                                <a-form-item v-bind="formBtnLayout">
                                    <a-button type="primary" @click="create">保存并完善人才信息</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
import moment from 'moment'

export default {
    components: {
        listHeader,
    },
    data () {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
            },
            data: {},
            config: {},
            dataSource: [],
        }
    },
    Watch: {},
    created () {
        this.setData()
        this.getData()
    },
    methods: {
        autoSearch (value) {
            this.postData.keyword = value

            this.http('/manage/supplier/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.supid = value;
        },
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue;
                }
                this.postData[i] = this.$route.query[i];
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/rencai/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/rencai/save';

                        this.http(url, values, (res) => {
                            this.toast({ content: '操作成功' }, () => {
                                this.$router.push('/rencai/edit?rid=' + res.rid);
                            });
                            // this.$router.go(-1);
                        }, null, 1);
                    }
                },
            )
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
