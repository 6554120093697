<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="补缴月"
                    >
                        <a-month-picker
                            style="width: 100%"
                            placeholder="补缴月"
                            v-decorator="['month', {rules: [{ required: true, message: '请选择补缴月' }]}]"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        bzid: {
            type: String,
            default: '',
        },
        bzdid: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '补缴公积金',
            form: this.$form.createForm(this),
            config: {},
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = ''
                        let data = values;
                        data.bzid = this.bzid;
                        data.bzdid = this.bzdid;

                        url = '/manage/bzhufang/save-detail'
                        data.month = data.month ? data.month.format('YYYY-MM') : ''
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
