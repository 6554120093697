import layout from '@/components/common/layout';
import stsdetailList from '../views/stsdetail/list';

export default [
    {
        path: '/stsdetail',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "收款列表",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '229',// 父选项
                        child: 'stsdetail_list',// 子选项
                    }
                },
                component: stsdetailList,
            },
        ]
    },
];
