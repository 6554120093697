// 系统
import layout from '@/components/common/layout';

// 角色
import roleList from '../views/role/list';
import roleCreate from '../views/role/create';

// 用户
import accountList from '../views/account/list';
import accountCreate from '../views/account/create';

// 主账户
import accountManage from '../views/account/manage';

export default [
  {
    path: '/role',
    component: layout,
    children: [
      {
        path: 'list',
        meta: {
          metaInfo: {
            title: "角色管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_role',// 子选项
          },
        },
        component: roleList,
      },
      {
        path: 'create',
        meta: {
          metaInfo: {
            title: "新增角色",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_role',// 子选项
          },
        },
        component: roleCreate,
      },
      {
        path: 'edit',
        meta: {
          metaInfo: {
            title: "编辑角色",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_role',// 子选项
          },
        },
        component: roleCreate,
      },
    ]
  },
  {
    path: '/account',
    component: layout,
    children: [
      {
        path: 'list',
        meta: {
          metaInfo: {
            title: "用户管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_user',// 子选项
          },
        },
        component: accountList,
      },
      {
        path: 'create',
        meta: {
          metaInfo: {
            title: "新增员工",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_user',// 子选项
          },
        },
        component: accountCreate,
      },
      {
        path: 'edit',
        meta: {
          metaInfo: {
            title: "编辑员工",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_user',// 子选项
          },
        },
        component: accountCreate,
      },
      {
        path: 'account',
        meta: {
          metaInfo: {
            title: "主账户管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '1',// 父选项
            child: 'system_account',// 子选项
          },
        },
        component: accountManage,
      },
    ]
  },
];
