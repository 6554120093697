<template>
    <a-modal
        title="导入结果"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        :okText="error_num > 0 ? '下载失败列表' : '确定'"
        cancelText="取消"
        @cancel="cancelFun"
        @ok="okFun"
        :confirm-loading="confirmLoading"
    >
        <div class="bomb-remind">
            <div class="bomb-remind-text flex">
                <icon-font type="icontips" class="main" />
                <div class="right-remind-text">
                    <h3>本次导入{{ parseInt(error_num) + parseInt(success_num) }}条记录！</h3>
                    <div class="down-text">
                        <span class="main" v-if="success_num > 0">{{ success_num }}条导入成功！</span>
                        <span class="red" v-if="error_num > 0">{{ error_num }}条导入失败！</span>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import { Icon } from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});

export default {
    components: {
        IconFont,
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        error_data: {
            type: [Array],
            default: [],
        },
        thead: {
            type: [Array],
            default: [],
        },
        error_num: {
            type: [String, Number],
            default: 0,
        },
        success_num: {
            type: [String, Number],
            default: 0,
        },
    },
    data() {
        return {
            showModal: this.visible,
            confirmLoading: false,
        };
    },
    watch: {
        // 实现双向绑定
        visible(val) {
            this.showModal = val;

            if (val === false) {
                this.confirmLoading = false;
            }
        },
        showModal(val) {
            this.$emit("changeModal", val);
        },
    },
    methods: {
        closeModal() {
            this.showModal = false;
        },
        okFun() {
            this.confirmLoading = true;

            if(this.error_num > 0) {
                require.ensure([], () => {
                    const { export_json_to_excel } = require('../../assets/excel/Export2Excel');

                    let thead = JSON.parse(JSON.stringify(this.thead));
                    thead.push('原因');
                    let col_reason = this.parseExcelCol(thead.length -1);
                    let style = {};
                    style[col_reason + '1'] = {font: {color: {rgb: "FF0000"} } };
                    let data = [];
                    for (let i in this.error_data) {
                        let item = [];
                        for(let j in this.error_data[i].data) {
                            item.push(this.error_data[i].data[j]);
                        }
                        item.push(this.error_data[i].reason);
                        // 加入数据
                        data.push(item);
                        // 设置原因标红
                        let row = parseInt(i) + 2;
                        style[col_reason + row] = {font: {color: {rgb: "FF0000"} } };
                    }

                    export_json_to_excel(thead, data, '导入失败记录', [], style);

                    this.confirmLoading = false;
                })
            } else {
                this.closeModal();
                this.$emit("okFun");
            }
        },
        /**
         * 返回列数
         * @param colIndex excel列索引【0-25 A-Z】
         * @param str
         * @returns {string}
         */
        parseExcelCol(colIndex, str = '') {
            let index = [
                'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
                'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            ];

            let next_digit = parseInt(colIndex / 26);
            let now_digit = colIndex % 26;
            if(next_digit > 26) {
                this.parseExcelCol(next_digit, index[now_digit] + str)
            } else {
                return next_digit > 0 ? index[next_digit] + index[now_digit] + str : index[now_digit] + str;
            }
        },
        cancelFun() {
            this.closeModal();
            this.$emit("cancelFun");
        }
    }
}
</script>
