<template>
    <div class="wrap">
        <listHeader title="编辑企业"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="企业名称"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入企业名称"
                                             v-decorator="['cname', {rules: [{ required: true, max: 16, message: '请输入企业名称, 最大16个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item v-bind="formBtnLayout">
                                    <a-button type="primary" @click="create">保存</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
import moment from 'moment'

export default {
    components: {
        listHeader,
    },
    data () {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                cid: '',
            },
            data: {},
            config: {},
        }
    },
    Watch: {},
    created () {
        this.setData()
        this.getData()
    },
    methods: {
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue;
                }
                this.postData[i] = this.$route.query[i];
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/company/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

                this.form.setFieldsValue({
                    cname: res.data.cname,
                });

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/company/edit';
                        values.cid = this.postData.cid;

                        this.http(url, values, (res) => {
                            this.toast({ content: '操作成功' }, () => {});
                            this.$router.go(-1);
                        }, null, 1);
                    }
                },
            )
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
