import layout from '@/components/common/layout';
import rencaiList from '../views/rencai/list';
import rencaiUnassignList from '../views/rencai/unassignlist';
import rencaiMyList from '../views/rencai/mylist';
import rencaiCreate from '../views/rencai/create';
import rencaiEdit from '../views/rencai/edit';

export default [
    {
        path: '/rencai',
        component: layout,
        children: [
            {
                path: 'list-unassign',
                meta: {
                    metaInfo: {
                        title: "待分配人才",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rencai_unassign',// 子选项
                    }
                },
                component: rencaiUnassignList,
            },
            {
                path: 'list-my',
                meta: {
                    metaInfo: {
                        title: "我的人才",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rencai_my',// 子选项
                    }
                },
                component: rencaiMyList,
            },
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部人才",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rencai_all',// 子选项
                    }
                },
                component: rencaiList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增人才",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rencai_my',// 子选项
                    }
                },
                component: rencaiCreate,
            },
            {
                path: 'edit',
                meta: {
                    metaInfo: {
                        title: "编辑人才",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rencai_my',// 子选项
                    }
                },
                component: rencaiEdit,
            }
        ]
    },
];
