import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'

// ant
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';

Vue.use(Antd)

//富文本编辑器
import CKEditor from '@ckeditor/ckeditor5-vue'

Vue.use(CKEditor)

// LocalStorage
import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

// Cookies
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)

// meta加载
import Meta from 'vue-meta'

Vue.use(Meta)

// 监听路由
import mixin from './mixin'

Vue.mixin(mixin)


Vue.config.productionTip = false

new Vue({
    router,
    store,
    metaInfo () {
        return {
            title: this.$store.state.metaInfo.title
                ? this.$store.state.metaInfo.title
                : '爱沃客信息科技',
            meta: [
                {
                    name: 'keywords',
                    content: this.$store.state.metaInfo.keywords
                        ? this.$store.state.metaInfo.keywords
                        : '',
                }, {
                    name: 'description',
                    content: this.$store.state.metaInfo.description
                        ? this.$store.state.metaInfo.description
                        : '',
                },
            ],
        }
    },
    render: h => h(App),
}).$mount('#app')
