<template>
    <a-modal
        title="新增公积金比例"
        :visible="visible"
        class="modal-initialize"
        width="508px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <!-- 修改社保 -->
            <div class="bomb-form">
                <!--<div class="form-title">
                    <h4>正在与<span>{{ cname }}</span>续签合同</h4>
                    <h5 class="red">注：若社保基数为0，则默认不缴纳社保！</h5>
                </div>-->
                <a-form class="inline-form" :form="form">
                    <a-form-item label="原登录密码" v-bind="formItemLayout">
                        <a-input type="password" placeholder="请输入原登录密码" v-decorator="['oldpasswd', {rules: [{ required: true, message: '请输入原登录密码' }, { validator: changePasswd, message: '登录密码为[8-16位、字母开头、不全为字母]' }]} ]"/>
                    </a-form-item>

                    <a-form-item label="新登录密码" v-bind="formItemLayout">
                        <a-input type="password" placeholder="请输入新登录密码" v-decorator="['passwd', {rules: [{ required: true, message: '请输入新登录密码' }, { validator: changePasswd, message: '登录密码为[8-16位、字母开头、不全为字母]' }]} ]"/>
                    </a-form-item>

                    <a-form-item label="确认密码" v-bind="formItemLayout">
                        <a-input type="password" placeholder="请输入确认密码" v-decorator="['confirmpasswd', {rules: [{ required: true, message: '请输入确认密码' }, { validator: compareToPassword, message: '两次密码不相同' }]} ]"/>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                showModal: this.visible,
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    },
                },
            };
        },
        watch: {
            // 实现双向绑定
            visible(val) {
                this.showModal = val;

                if (val === false) {
                    this.form.resetFields(); // 重置表单
                }
            },
            showModal(val) {
                this.$emit("changeModal", val);
            },
        },
        methods: {
            closeModal() {
                this.showModal = false;
            },
            okFun() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {
                            this.http('/manage/manager/edit-passwd', values, (res) => {
                                this.toast({content: '修改成功'});
                                this.closeModal();
                                this.$emit("confirm");
                            }, null, 1);
                        }
                    }
                );
            },
            cancelFun() {
                this.closeModal();
            },
            // 自定义验证密码
            changePasswd(rule, value, callback) {
                let validate = /^[a-zA-Z](?![a-zA-Z]+$).{7,15}$/.test(value);
                if(value && validate === false) {
                    callback(true);
                } else {
                    callback();
                }
            },
            // 自定义验证【确认密码】
            compareToPassword(rule, value, callback) {
                if (value && value !== this.form.getFieldValue('passwd')) {
                    return false;
                } else {
                    return true;
                }
            },
        }
    }
</script>
