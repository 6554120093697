import layout from '@/components/common/layout';
import jobList from '../views/job/list';
import jobCreate from '../views/job/create';
import jobEdit from '../views/job/edit';

export default [
    {
        path: '/job',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部职位",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增职位",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobCreate,
            },
            {
                path: 'edit',
                meta: {
                    metaInfo: {
                        title: "编辑职位",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobEdit,
            },
        ]
    },
];
