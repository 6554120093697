import layout from '@/components/common/layout';
import bsalaryList from '../views/bsalary/list';
import bsalaryDetail from '../views/bsalary/detail';

export default [
    {
        path: '/bsalary',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部工资",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bsalary_list',// 子选项
                    }
                },
                component: bsalaryList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部工资明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bsalary_list',// 子选项
                    }
                },
                component: bsalaryDetail,
            },
        ]
    },
];
