<template>
    <div class="wrap">
        <listHeader title="编辑职位"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">

                <a-tabs type="card" @change="tabChange" default-active-key="info">
                    <a-tab-pane key="info" tab="职位信息"></a-tab-pane>
                    <a-tab-pane key="template" tab="采集模板"></a-tab-pane>
                </a-tabs>

                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="职位类型"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-radio-group v-decorator="['jobtype', {rules: [{ required: false, message: '请选择职位类型' }]}]" @change="changeJobtype">
                                        <a-radio v-for="(item, key) in config.jobtype" :key="key" :value="key">{{ item }}</a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="职位名称"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入职位名称"
                                             v-decorator="['jobname', {rules: [{ required: true, max: 32, message: '请输入职位名称, 最大16个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="供应商"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    v-if="showSupplier"
                                >
                                    <a-auto-complete
                                        class="global-search"
                                        size="large"
                                        style="width: 100%"
                                        placeholder="请输入供应商名称关键词"
                                        option-label-prop="title"
                                        @select="autoSelect"
                                        @focus="autoSearch"
                                        @search="autoSearch"
                                        v-decorator="['supid', {rules: [{ required: false, message: '请选择供应商' }]}]"
                                    >
                                        <template slot="dataSource">
                                            <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                                没有找到供应商，去<a href="/supplier/create" target="_blank">新增供应商</a>
                                            </a-select-option>
                                            <a-select-option v-else v-for="item in dataSource" :key="item.supid"
                                                             :title="item.supname">
                                                {{ item.supname }}
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </a-form-item>

                                <a-form-item
                                    label="管理费"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入该职位员工的管理费"
                                             v-decorator="['managefee', {rules: [{ required: false, validator: checkMoney, message: '请输入该职位员工的管理费' }], initialValue: '0'}]"
                                             suffix="元/月"
                                    />
                                </a-form-item>

                                <a-form-item v-bind="formBtnLayout">
                                    <a-button type="primary" @click="create">保存</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader'
import moment from 'moment'

export default {
    components: {
        listHeader,
    },
    data () {
        return {
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                jid: '',
            },
            data: {},
            config: {},
            dataSource: [],
            showSupplier: false,
        }
    },
    Watch: {},
    created () {
        this.setData()
        this.getData()
    },
    methods: {
        autoSearch (value) {
            this.postData.keyword = value

            this.http('/manage/supplier/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.supid = value;
        },
        tabChange(key) {
            switch (key) {
                case 'info': // 基本信息
                    this.$router.push('/job/edit?jid=' + this.postData.jid);
                    break
                case 'template': // 采集模板
                    this.$router.push('/jobtpl/list?jid=' + this.postData.jid);
                    break
                default:
                    break
            }
        },
        changeJobtype(e) {
            if (e.target.value == '5') {
                this.showSupplier = true;
            } else {
                this.showSupplier = false;
            }
        },
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue;
                }
                this.postData[i] = this.$route.query[i];
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/job/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;


                // 显示状态
                this.showSupplier = res.data.jobtype == 5 ? true : false;

                // 供应商处理
                if (res.data.supid) {
                    let supplier = {};
                    supplier.supid = res.data.supid;
                    supplier.supname = res.data.supname;

                    this.dataSource.push(supplier);
                }

                this.$nextTick((r) => {
                    this.form.setFieldsValue({
                        jobtype: res.data.jobtype,
                        jobname: res.data.jobname,
                        supid: res.data.supid ? res.data.supid : null,
                        managefee: res.data.managefee,
                    });
                });

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/job/edit';
                        values.jid = this.postData.jid;

                        this.http(url, values, (res) => {
                            this.toast({ content: '操作成功' }, () => {});
                            this.$router.go(-1);
                        }, null, 1);
                    }
                },
            )
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
