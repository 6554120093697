import layout from '@/components/common/layout';
import bmanageList from '../views/bmanage/list';
import bmanageDetail from '../views/bmanage/detail';

export default [
    {
        path: '/bmanage',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部管理费",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bmanage_list',// 子选项
                    }
                },
                component: bmanageList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部管理费明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bmanage_list',// 子选项
                    }
                },
                component: bmanageDetail,
            },
        ]
    },
];
