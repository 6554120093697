import layout from '@/components/common/layout';
import supplierList from '../views/supplier/list';
import supplierCreate from '../views/supplier/create';

export default [
    {
        path: '/supplier',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部供应商",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'supplier_list',// 子选项
                    }
                },
                component: supplierList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增供应商",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'supplier_list',// 子选项
                    }
                },
                component: supplierCreate,
            },
        ]
    },
];
