<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="待入职时间"
                    >
                        <a-date-picker
                            placeholder="待入职时间"
                            v-decorator="['time', {rules: [{ required: true, message: '请选择待入职时间' }]}]"
                        />
                    </a-form-item>

                    <a-form-item
                        label="备注"
                    >
                        <a-textarea
                            v-decorator="['viremark', {rules: [{ required: false, max: 128, message: '备注不超过128个字符' }], initialValue: ''}]"
                            placeholder="请填写回访备注"
                            :auto-size="{ minRows: 2 }"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [Array, String],
            default: [],
        },
        rcname: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '安排待入职',
            form: this.$form.createForm(this),
            config: {},
            dataSource: {},
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
            if (this.id) {
                this.title = '安排待入职 ' + this.rcname;
            }
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/rcinterview/job';
                        let data = values;

                        data.id = this.id;
                        data.jobtime = data.time ? data.time.format('YYYY-MM-DD') : '';

                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
