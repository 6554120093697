import layout from '@/components/common/layout';
import bzhufangList from '../views/bzhufang/list';
import bzhufangDetail from '../views/bzhufang/detail';

export default [
    {
        path: '/bzhufang',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部公积金",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bzhufang_list',// 子选项
                    }
                },
                component: bzhufangList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部公积金明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bzhufang_list',// 子选项
                    }
                },
                component: bzhufangDetail,
            },
        ]
    },
];
