<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="合同编号"
                    >
                        <a-input placeholder="请输入合同编号"
                                 v-decorator="['cono', {rules: [{ required: false, max: 64, message: '请输入合同编号,最大64字符' }], initialValue: ''}]"/>
                    </a-form-item>

                    <a-form-item
                        label="合同截止"
                    >
                        <a-date-picker
                            placeholder="合同截止日期"
                            v-decorator="['expiretime', {rules: [{ required: true, message: '请选择合同截止日期' }]}]"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        empid: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '续签合同',
            form: this.$form.createForm(this),
            config: {},
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/empcontract/save';
                        let data = values;

                        data.empid = this.empid;
                        data.expiretime = data.expiretime ? data.expiretime.format('YYYY-MM-DD') : '';

                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
