<template>
    <div class="wrap">
        <listHeader :title="roleid > 0 ? '编辑角色' : '新增角色'"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">
                <div class="wrap-column-title flex align-items"><span class="line"></span><h3>基本设置</h3></div>
                <div class="wrap-form-info">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">
                                <a-form-item
                                    label="角色名称"
                                    :colon = false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请填写角色名称" v-decorator="['rolename', {rules: [{ required: true, max: 20, message: '请填写角色名称（最大20位）' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="状态"
                                    :colon = false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-radio-group v-decorator="['status', {rules: [{ required: true, message: '请选择状态' }], initialValue: ''}]">
                                        <a-radio v-for="(item, key) in statuses" :key="key" :value="key">{{ item }}</a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="权限"
                                    :colon = false
                                    v-bind="formItemLayout"
                                    required
                                    :validateStatus="limit.validateStatus"
                                    :help="limit.help"
                                >
                                    <tree :nodes="limitlist" :setting="setting" @onCreated="ztreeOnCreate" @onCheck="ztreeOnCheck"></tree>
                                </a-form-item>

                                <a-form-item
                                        v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">确定</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import listHeader from '@/components/list/listHeader';
    import tree from "vue-giant-tree";

    export default {
        components: {
            listHeader,
            tree,
        },
        data() {
            return {
                formItemLayout: {
                    labelCol: {
                        span: 5
                    },
                    wrapperCol: {
                        span: 19
                    },
                },
                formBtnLayout: {
                    wrapperCol: {
                        span: 19,
                        offset: 5,
                    },
                },
                form: this.$form.createForm(this),
                limit: {
                    validateStatus: 'success',
                    help: '',
                },
                limitlist: [],// 菜单列表
                statuses: {},// 状态
                ztreeObj: null,// ztree对象
                setting: {
                    check: {
                        enable: true,
                        chkboxType: {
                            "Y": "ps",
                            "N": "s"
                        }
                    },
                    data: {
                        key: {
                            url: 'locationUrl',
                        },
                        simpleData: {
                            enable: true,
                            idKey: 'limitid',
                            pIdKey: 'parent',
                        }
                    },
                },
                roleid: 0,
            }
        },
        created() {
            if(this.$route.query.roleid !== undefined) {
                this.roleid = this.$route.query.roleid;
            }
            this.getData();
        },
        methods: {
            getData() {
                this.http('/manage/system/role-info', {roleid: this.roleid}, (res) => {
                    for(let i in res.limitlist) {
                        if(res.limitlist[i].type == 0) {
                            res.limitlist[i].name = res.limitlist[i].name + '(菜单)';
                        } else {
                            res.limitlist[i].name = res.limitlist[i].name + '(权限)';
                        }
                    }

                    this.limitlist = res.limitlist;
                    this.statuses = res.config.statuses;
                    // 角色信息
                    if(this.roleid > 0) {
                        if(res.roleinfo) {
                            this.form.setFieldsValue({rolename: res.roleinfo.rolename, status: res.roleinfo.status});
                        }

                        if(res.bindlist) {
                            let bindLimitid = [];
                            for (let i in res.bindlist) {
                                bindLimitid.push(res.bindlist[i].limitid);
                            }
                            for (let i in this.limitlist) {
                                if (this.inArray(this.limitlist[i].limitid, bindLimitid) > -1) {
                                    this.limitlist[i].checked = true;
                                }
                            }
                        }
                    } else {
                        // 默认类型
                        for(let i in this.statuses) {
                            this.form.setFieldsValue({status: i});
                            break;
                        }
                    }
                }, null, 2);
            },
            // ztree渲染完成
            ztreeOnCreate(e) {
                this.ztreeObj = e;
                // 默认展开
                e.expandAll(true);
            },
            ztreeOnCheck(e) {
                let limit = this.computedLimit();
                if(limit.length === 0) {
                    this.limit.validateStatus = 'error';
                    this.limit.help = '请选择权限';
                } else {
                    this.limit.validateStatus = 'success';
                    this.limit.help = '';
                }
            },
            computedLimit() {
                let nodes = this.ztreeObj.getCheckedNodes();
                let limitid = [];
                for (let i in nodes) {
                    limitid.push(nodes[i].limitid);
                }
                return limitid;
            },
            // 自定义验证密码
            changePasswd(rule, value, cb) {
                let validate = /^[a-zA-Z](?![a-zA-Z]+$).{7,15}$/.test(value);
                if(value && validate === false) {
                    cb(true);
                } else {
                    cb();
                }
            },
            create() {
                let limitid = this.computedLimit();
                if(limitid.length === 0) {
                    this.limit.validateStatus = 'error';
                    this.limit.help = '请选择权限';
                } else {
                    this.limit.validateStatus = 'success';
                    this.limit.help = '';
                }

                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {
                            if(limitid.length === 0) {
                                return false;
                            }
                            let url = '';
                            let param = values;
                            param.limitid = limitid;
                            if(this.roleid) {
                                param.roleid = this.roleid;
                                url = '/manage/system/edit-role';
                            } else {
                                url = '/manage/system/save-role';
                            }
                            this.http(url, param, (res) => {
                                this.toast({content: '操作成功'});
                                //this.$router.push('/role/list');
                                this.$router.go(-1);
                            }, null, 1);
                        }
                    }
                );
            },
        },
    }
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
