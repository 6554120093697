<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-list-content">

            <a-tabs type="card" @change="tabChange" default-active-key="attachment">
                <a-tab-pane key="info" tab="简历信息"></a-tab-pane>
                <a-tab-pane key="attachment" tab="附件简历"></a-tab-pane>
                <a-tab-pane key="visit" tab="回访记录"></a-tab-pane>
                <a-tab-pane key="interview" tab="面试安排"></a-tab-pane>
                <a-tab-pane key="job" tab="入职安排"></a-tab-pane>
            </a-tabs>

            <div class="hro-table hro-table-only">
                <hro-table
                    :columns="columns"
                    :dataSource="data"
                    :loading="loading"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :scroll="scroll"
                    :rowKey="selectedKey"
                    @actionFun="actionFun"
                >

                </hro-table>
            </div>
        </div>

        <list-bottom
            :page="postData.page"
            :pagesize="postData.pagesize"
            :total="total"
            :data="data"
            :selectedRowKeys="selectedRowKeys"
            :condition="{ checkall: true, }"
            :scenario="scenario"
            @checked="onCheckAllChange"
            @page="pageTo"
            @optionFun="bottomOptionFun"
        >
        </list-bottom>


        <!--上传附件-->
        <rencai-attachment
            v-model="showModal"
            :rid="rid"
            :id="id"
            @confirm="getData">
        </rencai-attachment>
    </div>
</template>

<script>
import $ from 'jquery'
import searchScreen from '@/components/list/searchScreen'
import listBottom from '@/components/list/listBottom'
import hroTable from '@/components/list/table'
import listHeader from '@/components/list/listHeader'
import rencaiAttachment from '@/components/modal/rencaiAttachment'

export default {
    components: {
        listBottom,
        hroTable,
        searchScreen,
        listHeader,
        rencaiAttachment,
    },
    data () {
        return {
            title: '附件简历',
            scenario: 'rcattachmentList', // 页面标识
            selectedRowKeys: [], // 选中行的key
            selectedRowKeysItem: [], // 选中行的item
            selectedKey: 'id', // 数据主键字段（唯一）
            loading: false,// 请求
            scroll: null, //滚动
            data: [],
            setCondition: {
                fields: [],// 显示字段
                pagesizes: {},// 每页显示数筛选条件
            },
            postData: {
                rid: '',
                status: '',
                page: 1,
                pagesize: 10,
                showfield: [], // 显示字段
            },
            total: 0,
            config: {},
            showModal: false, // 弹框
            rid: '',
            id: '',
        }
    },
    computed: {
        columns () {
            // 处理显示字段
            let columns = []
            for (let i in this.setCondition.fields) {
                let index = this.inArray(this.setCondition.fields[i].key, this.postData.showfield)
                if (index > -1) {
                    let item = {
                        title: this.setCondition.fields[i].text,
                        dataIndex: this.setCondition.fields[i].key,
                    }

                    if (i == 0 && this.setCondition.fields[i].default == 1) {
                        item.fixed = 'left'
                        item.width = 100
                    }

                    columns.push(item)
                }
            }

            let item = {
                title: '操作',
                dataIndex: 'rcattachment_action',
                scopedSlots: { customRender: 'rcattachment_action' },
            }

            let len = columns.length
            if (len > 7) {
                item.fixed = 'right'
                item.width = 100
            }
            else if (len > 0 && len < 7) {
                columns[0].fixed = ''
                columns[0].width = ''
            }
            columns.push(item)

            let x = 300 + (len - 2) * 150
            let table_width = $('.hro-table').width()
            this.scroll = x > table_width ? { x: x } : null

            return columns
        },
    },
    watch: {
        '$store.state.showLayoutMenu' (val) {
            $('.list-bottom').width($('.wrap-list-content').width())
        },
    },
    created () {
        // 当浏览器大小变化时
        this.listenListWindow()

        // 初始化postData
        this.setData()
        // 请求数据
        this.getData()
    },
    methods: {
        tabChange(key) {
            switch (key) {
                case 'info': // 基本信息
                    this.$router.push('/rencai/edit?rid=' + this.postData.rid);
                    break
                case 'attachment': // 联系人
                    this.$router.push('/rcattachment/list?rid=' + this.postData.rid);
                    break
                case 'visit': // 回访记录
                    this.$router.push('/rcvisit/list-visit?rid=' + this.postData.rid);
                    break
                case 'interview': // 面试安排
                    this.$router.push('/rcinterview/list-interview?rid=' + this.postData.rid);
                    break
                case 'job': // 入职安排
                    this.$router.push('/rcjob/list-job?rid=' + this.postData.rid);
                    break
                default:
                    break
            }
        },
        getData () {
            // 加载等待
            this.loading = true
            // 路由变化
            // this.changeRouter();
            this.http('/manage/rcresume/list', this.postData, (res) => {
                this.loading = false

                // 数据渲染
                this.setCondition.fields = res['_fields']
                this.setCondition.pagesizes = res['_pagesizes']
                this.data = res.list.data
                this.total = parseInt(res.list.count)
                this.postData.pagesize = parseInt(res.list.pagesize)
                this.config = res.config

                // 初始化底部宽度
                this.$nextTick(() => {
                    $('.list-bottom').width($('.wrap-list-content').width())
                })
            }, null, 2)
        },
        // 设置参数
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue
                }

                if (i === 'showfield') {
                    this.postData[i] = typeof this.$route.query[i] === 'string'
                        ? [this.$route.query[i]]
                        : this.$route.query[i]
                }
                else {
                    this.postData[i] = this.$route.query[i]
                }
            }

            // 初始化显示字段
            if (this.$route.query['showfield'] === undefined) {
                this.postData['showfield'] = [
                    'title',
                    'ordernum',
                    'status_str',
                    'addtime_str']
            }
        },
        // 路由监听
        changeRouter () {
            this.$router.push({ path: '', query: this.postData })
        },
        // 头部操作
        headerOptionFun (e) {
            switch (e.op) {
                case 'create': // 新增
                    this.id = '';
                    this.rid = this.postData.rid;
                    this.showModal = true
                    break
                default:
                    break
            }
        },
        // 列表操作
        actionFun (e) {
            switch (e.op) {
                case 'edit':// 编辑
                    this.id = e[this.selectedKey]
                    this.showModal = true
                    break
                case 'close':// 关闭
                    this.confirm({ title: '删除简历附件', content: `确认删除该简历附件？` }, () => {
                        this.http('/manage/rcresume/close', { id: e[this.selectedKey] }, (res) => {
                            this.toast({ content: '操作成功' }, () => {
                                this.getData()
                            })
                        }, null, 1)
                    })
                    break
                case 'open':// 开启
                    this.confirm({ title: '恢复简历附件', content: `确认恢复该简历附件？` }, () => {
                        this.http('/manage/rcresume/open', { id: e[this.selectedKey] }, (res) => {
                            this.toast({ content: '操作成功' }, () => {
                                this.getData()
                            })
                        }, null, 1)
                    })
                    break
                default:
                    break
            }
        },
        // 底部操作
        bottomOptionFun (e) {
            if (this.selectedRowKeys.length === 0) {
                return false
            }

            switch (e.op) {
                default:
                    break
            }
        },
    },
}
</script>


<style lang="less" src="../../assets/css/list/list.less"></style>