import layout from '@/components/common/layout';
import employeeList from '../views/employee/list';
import employeeEdit from '../views/employee/edit';

export default [
    {
        path: '/employee',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部员工",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'employee_list',// 子选项
                    }
                },
                component: employeeList,
            },
            {
                path: 'edit',
                meta: {
                    metaInfo: {
                        title: "编辑员工",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'employee_list',// 子选项
                    }
                },
                component: employeeEdit,
            },
        ]
    },
];
