<template>
    <div class="user">
        <div class="login-form">
          <div class="logo"><a href="/"> <img src="../../assets/img/logo.png" alt=""></a></div>
            <div class="login-form-column">
                <a-form :form="form">

                    <a-form-item>
                        <a-input placeholder="请输入您公司名称" v-decorator="['etname', {rules: [{ required: true, message: '请输入您公司名称' }], initialValue: null}]"/>
                    </a-form-item>


                    <a-form-item>
                        <a-input placeholder="请设置您的项目账户，如iwowke" :addonAfter="domain_str"
                                 v-decorator="['domain', {rules: [{ required: true, validator: checkdomainreg, message: '项目账户为[不小于3位，字母开头，字母、数字、下划线组成]' }], initialValue: null}]"
                                 @blur="checkdomain"
                        />
                    </a-form-item>


                    <a-form-item>
                        <a-input placeholder="请输入手机号" v-decorator="['mobile', {rules: [{ required: true, min:11, max:11, message: '请输入11位手机号' }], initialValue: null}]" type="number"/>
                    </a-form-item>

                    <a-form-item>
                        <a-input :type="passwdType" placeholder="请输入登录密码" v-decorator="['passwd', {rules: [{ required: true, validator: checkpasswd, message: '登录密码为[8-16位、字母开头、不全为字母]' }], initialValue: null}]" @keyup.enter="create">
                            <i :class="`iconfont ${showEye}`" slot="suffix" @click="toggleEye"></i>
                        </a-input>
                    </a-form-item>


                    <a-form-item>
                        <a-button type="primary" @click="create">注册体验会员</a-button>
                    </a-form-item>

                    <div class="register">
                       <p>已有账户？<a href="/manager/login">立即登录>></a></p>
                    </div>
                </a-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                value: 1,
                formItemLayout: {
                    labelCol: {
                        span: 5
                    },
                    wrapperCol: {
                        span: 19
                    },
                },
                formBtnLayout: {
                    wrapperCol: {
                        span: 19,
                        offset: 5,
                    },
                },
                form: this.$form.createForm(this),
                showEye: 'iconpassword_hide',
                domain_str: '',
                domain: '',
                passwdType: 'password',
            }
        },
        computed: {
        },
        watch: {
            // 显示或隐藏密码
            showEye() {
                if (this.showEye === 'iconpassword_display') {
                    this.passwdType = 'text';
                } else if (this.showEye === 'iconpassword_hide') {
                    this.passwdType = 'password';
                }
            },
        },
        created() {
            this.domain_str = process.env.VUE_APP_DOMAIN;
            this.domain = document.domain;
        },
        methods: {
            // 显示隐藏密码
            toggleEye() {
                if (this.showEye === 'iconpassword_hide') {
                    this.showEye = 'iconpassword_display';
                } else {
                    this.showEye = 'iconpassword_hide';
                }
            },
            checkdomainreg(rule, value, callback) {
                let validate = /[a-z][\w\-]{2,}/.test(value);
                if(value <= 0 || validate === false) {
                    callback(true);
                } else {
                    callback();
                }
            },
            checkdomain(e) {
                let domain = e.target.value;
                this.http('/manage/login/check-domain', {domain: domain}, (res) => {}, null, 1);
            },
            checkpasswd(rule, value, callback) {
                let validate = /^[a-zA-Z](?![a-zA-Z]+$).{7,15}$/.test(value);
                if(value <= 0 || validate === false) {
                    callback(true);
                } else {
                    callback();
                }
            },
            create() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {

                            this.http('/manage/login/register', values, (res) => {
                                // this.toast({content: '创建成功'});
                                this.setObjectLocalStorage('community_menulist', res.menulist);
                                this.setObjectLocalStorage('community_limit', res.limitflag)
                                this.loginSetCookie(res.userinfo);

                                this.$router.push(res.userinfo.firstUrl);
                            }, null, 1);
                        }
                    }
                );
            },
        },
    }
</script>

<style lang="less" src="../../assets/css/center/login.less"></style>
