import layout from '@/components/common/layout';
import empContractList from '../views/empcontract/list';
import empContractEmployee from '../views/empcontract/employee';
import empContractRemind from '../views/empcontract/remindlist';

export default [
    {
        path: '/empcontract',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "合同管理",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'empcontract_list',// 子选项
                    }
                },
                component: empContractList,
            },
            {
                path: 'list-employee',
                meta: {
                    metaInfo: {
                        title: "员工合同",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'employee_list',// 子选项
                    }
                },
                component: empContractEmployee,
            },
            {
                path: 'list-remind',
                meta: {
                    metaInfo: {
                        title: "合同提醒",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '132',// 父选项
                        child: 'empcontract_remind',// 子选项
                    }
                },
                component: empContractRemind,
            },
        ]
    },
];
