import layout from '@/components/common/layout';
import visitList from '../views/rcvisit/list';
import visitRencai from '../views/rcvisit/visit';
import visitRemindList from '../views/rcvisit/remindlist';

export default [
    {
        path: '/rcvisit',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部回访",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcvisit_list',// 子选项
                    }
                },
                component: visitList,
            },
            {
                path: 'list-visit',
                meta: {
                    metaInfo: {
                        title: "回访记录",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcvisit_list',// 子选项
                    }
                },
                component: visitRencai,
            },
            {
                path: 'list-remind',
                meta: {
                    metaInfo: {
                        title: "回访提醒",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcvisit_remind',// 子选项
                    }
                },
                component: visitRemindList,
            },
        ]
    },
];
