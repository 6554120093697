<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">

                <a-tabs type="card" @change="tabChange" default-active-key="info">
                    <a-tab-pane key="info" tab="简历信息"></a-tab-pane>
                    <a-tab-pane key="attachment" tab="附件简历"></a-tab-pane>
                    <a-tab-pane key="visit" tab="回访记录"></a-tab-pane>
                    <a-tab-pane key="interview" tab="面试安排"></a-tab-pane>
                    <a-tab-pane key="job" tab="入职安排"></a-tab-pane>
                </a-tabs>

                <div class="wrap-form-info">
                    <a-row>

                        <a-divider orientation="left">
                            基本信息
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="姓名"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入人才姓名"
                                             v-decorator="['rcname', {rules: [{ required: true, max: 16, message: '请输入人才姓名, 最大16个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="手机"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="手机号和微信至少填写一项"
                                             v-decorator="['mobile', {rules: [{ required: false, validator: checkMobile, message: '请输入人才的手机号, 最大11个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="微信"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="手机号和微信至少填写一项"
                                             v-decorator="['wechat', {rules: [{ required: false, max: 64, message: '请输入人才的微信号, 最大64个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="身份证"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入人才的身份证号"
                                             v-decorator="['idcard', {rules: [{ required: false, validator: checkIdcard, message: '请输入人才的身份证号' }], initialValue: ''}]"
                                             @blur="idcardChange"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="性别"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-radio-group v-decorator="['sex', {rules: [{ required: false, message: '请选择人才性别' }]}]">
                                        <a-radio v-for="(item, key) in config.sex" :key="key" :value="key">{{ item }}</a-radio>
                                    </a-radio-group>
                                </a-form-item>

                                <a-form-item
                                    label="年龄"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input-number
                                        :min="0"
                                        :max="100"
                                        v-decorator="['age', {rules: [{ required: false, message: '周岁' }], initialValue: ''}]"
                                    /> 周岁
                                </a-form-item>

                                <a-form-item
                                    label="学历"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-select
                                        v-decorator="['degree', {rules: [{ required: false, message: '请选择学历' }], initialValue: ''}]"
                                    >
                                        <a-select-option v-for="(item, key) in config.degree" :key="key" :value="key">{{ item }}</a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="户籍地"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-cascader
                                        :showSearch="{ areaFilter }"
                                        placeholder="请选择人才户籍地"
                                        :options="config.areacode"
                                        @change="areaChange"
                                        v-decorator="['birtharea', {rules: [{ required: false, message: '请选择人才户籍地' }]}]"
                                    />
                                </a-form-item>
                            </a-form>
                        </a-col>


                        <a-divider orientation="left">
                            求职意向
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="意向工作"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入人才意向工作"
                                             v-decorator="['hopejob', {rules: [{ required: false, max: 32, message: '请输入人才意向工作, 最大32个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="意向工作地"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-cascader
                                        :showSearch="{ areaFilter }"
                                        placeholder="请选择人才意向工作地"
                                        :options="config.areacode"
                                        @change="hopeareaChange"
                                        v-decorator="['hopearea', {rules: [{ required: false, message: '请选择人才意向工作地' }]}]"
                                    />
                                </a-form-item>
                            </a-form>
                        </a-col>


                        <a-divider orientation="left">
                            供应商
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="供应商"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-auto-complete
                                        class="global-search"
                                        size="large"
                                        style="width: 100%"
                                        placeholder="请输入供应商名称关键词"
                                        option-label-prop="title"
                                        @select="autoSelect"
                                        @focus="autoSearch"
                                        @search="autoSearch"
                                        v-decorator="['supid', {rules: [{ required: false, message: '请选择供应商' }]}]"
                                    >
                                        <template slot="dataSource">
                                            <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                                没有找到供应商，去<a href="/supplier/create" target="_blank">新增供应商</a>
                                            </a-select-option>
                                            <a-select-option v-else v-for="item in dataSource" :key="item.supid"
                                                             :title="item.supname">
                                                {{ item.supname }}
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">保存简历</a-button>

                                    <a-button @click="$router.go(-1)" style="margin-left:10px;">返回</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>

                        <a-divider orientation="left">
                            人才标签
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="职位标签"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    v-if="tags.length > 0"
                                >
                                    <a-tag v-for="(item, key) in tags" color="blue" :key="item.id" closable @close="() => tagDel(item.id)">{{ item.tagname }}</a-tag>
                                </a-form-item>

                                <a-form-item v-bind="formBtnLayout">
                                    <a-button type="primary" @click="tagSave">新增标签</a-button>
                                </a-form-item>
                            </a-form>
                        </a-col>

                    </a-row>
                </div>
            </div>
        </div>

        <!--创建分类-->
        <rencai-tag
            v-model="showModal"
            :rid="rid"
            @confirm="getData">
        </rencai-tag>

        <!--回访-->
        <rencai-visit
            v-model="showModalVisit"
            :rid="rid"
            :rcname="rcname"
            @confirm="getData">
        </rencai-visit>

        <!--安排-->
        <rencai-interview
            v-model="showModalInterview"
            :rid="rid"
            :rcname="rcname"
            @confirm="getData">
        </rencai-interview>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';
import rencaiTag from '@/components/modal/rencaiTag';
import rencaiVisit from '@/components/modal/rencaiVisit';
import rencaiInterview from '@/components/modal/rencaiInterview';
import $ from "jquery";

export default {
    components: {
        listHeader,
        rencaiTag,
        rencaiVisit,
        rencaiInterview
    },
    data () {
        return {
            title: '编辑人才',
            scenario: 'rencaiEdit', // 页面标识
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                rid: 0,
                birtharea: '',
                hopearea: '',
            },
            data: {},
            config: {},
            tags: {},
            dateFormat: 'YYYY-MM-DD',
            showModal: false,
            showModalVisit: false,
            showModalInterview: false,
            rid: '',
            rcname: '',
            dataSource: [],
        }
    },
    Watch: {
        '$store.state.showLayoutMenu'(val) {
            $('.list-bottom').width($('.wrap-list-content').width());
        },
    },
    created () {
        this.setData()
        this.getData()
    },
    methods: {
        autoSearch (value) {
            this.postData.keyword = value

            this.http('/manage/supplier/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.supid = value;
        },
        areaFilter(inputValue, path) {
            return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
        },
        areaChange(value) {
            //console.log(value);
            //console.log(selectedOptions)

            let len = value.length;

            this.postData.birtharea = value[len - 1];

            // console.log(this.postData);
        },
        hopeareaChange(value) {
            //console.log(value);
            //console.log(selectedOptions)

            let len = value.length;

            this.postData.hopearea = value[len - 1];

            // console.log(this.postData);
        },
        tabChange(key) {
            switch (key) {
                case 'info': // 基本信息
                    this.$router.push('/rencai/edit?rid=' + this.postData.rid);
                    break
                case 'attachment': // 联系人
                    this.$router.push('/rcattachment/list?rid=' + this.postData.rid);
                    break
                case 'visit': // 回访记录
                    this.$router.push('/rcvisit/list-visit?rid=' + this.postData.rid);
                    break
                case 'interview': // 面试安排
                    this.$router.push('/rcinterview/list-interview?rid=' + this.postData.rid);
                    break
                case 'job': // 入职安排
                    this.$router.push('/rcjob/list-job?rid=' + this.postData.rid);
                    break
                default:
                    break
            }
        },
        idcardChange(e) {
            let birthyear = e.target.value.substring(6,10);
            let sex = e.target.value.substring(16,17);

            let nowDate = new Date();

            this.form.setFieldsValue({
                sex: sex % 2 == 1 ? 1 : 2,
                age: nowDate.getFullYear() - birthyear,
            });
        },
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue
                }
                this.postData[i] = this.$route.query[i]
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/rencai/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;
                this.tags = res.data.tag;

                this.postData.birtharea = this.data.birtharea;
                this.postData.hopearea = this.data.hopearea;

                // 供应商处理
                let supplier = {};
                supplier.supid = res.data.supid;
                supplier.supname = res.data.supname;
                this.dataSource.push(supplier);

                this.$nextTick((r) => {
                    this.form.setFieldsValue({
                        rcname: res.data.rcname,
                        mobile: res.data.mobile,
                        wechat: res.data.wechat,
                        idcard: res.data.idcard,
                        sex: parseInt(res.data.sex),
                        age: res.data.age,
                        degree: parseInt(res.data.degree),
                        hopejob: res.data.hopejob,
                        supid: res.data.supid > 0 ? res.data.supid : '',
                    });

                    window.setTimeout(()=>{
                        this.form.setFieldsValue({
                            birtharea: res.data.birtharea_str,
                            hopearea: res.data.hopearea_str,
                        });
                    }, 1000);
                });

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = url = '/manage/rencai/edit'

                        values.rid = this.postData.rid;
                        values.birtharea = this.postData.birtharea;
                        values.hopearea = this.postData.hopearea;

                        this.http(url, values, (res) => {
                            this.toast({ content: '保存成功' }, () => {
                                this.getData();
                            })
                        }, null, 1)
                    }
                },
            )
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                case 'create':
                    this.$router.push('/rencai/create');
                    break;
                case 'visit':
                    this.rid = this.postData.rid;
                    this.rcname = this.data.rcname;
                    this.showModalVisit = true;
                    break;
                case 'interview':
                    this.rid = this.postData.rid;
                    this.rcname = this.data.rcname;
                    this.showModalInterview = true;
                    break;
                default:
                    break;
            }
        },
        tagSave () {
            this.rid = this.postData.rid;
            this.showModal = true;
        },
        tagDel (tagid) {
            this.http('/manage/rctag/close', { id: tagid }, (res) => {
                this.toast({ content: '操作成功' }, () => {
                    this.getData()
                })
            }, null, 1)
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
