<template>
    <div>404页面不存在</div>
</template>
<script>
export default {
    data () {
        return {

        }
    },
}
</script>

