// mixin 公用方法
import $ from "jquery";
import store from "./store";
import md5 from 'js-md5';
import qs from 'qs';
import axios from 'axios';
import commonFun from '../src/assets/js/common';

export default {
    // 监听路由
    beforeRouteEnter(to, from, next) {
        // 跳转地址
        let firstUrl = '/center/index';

        // 非www域名不可跳首页
        if (to.path === '/') {
            if (document.domain.indexOf('www') === -1 && process.env.NODE_ENV !== "development")
            {
                if (commonFun.isLogin()) {
                    next(firstUrl);
                } else {
                    next('/manager/login');
                }
            }
        }

        // 动态加载meta
        if (to.meta.metaInfo) {
            store.commit("CAHNGE_META_INFO", to.meta.metaInfo);
        }

        if (to.meta.requireLogin) {// 验证登录
            if (commonFun.isLogin()) {// 已登录，顺利进入下一页
                next();
            } else {// 未登录，跳转登录页
                let url = '/manager/login';
                // 重定向
                if (to.query.redirect_url) {
                    url += '?redirect_url=' + encodeURIComponent(to.query.redirect_url);
                } else {
                    url += '?redirect_url=' + encodeURIComponent(to.fullPath);
                }
                next(url);
            }
        } else {
            if (commonFun.isLogin() && to.meta.forbidLogin) {// 禁止登录，返回前页
                // next(from.fullPath);
                next(firstUrl);
            } else {// 顺利进入下一页
                next();
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        store.commit("CHANGE_APILOCK", false);
        next();
    },
    methods: {
        /**
         * 警告弹框
         * @param options [
         *      title 标题
         *      content 内容
         *      param 自定义参数
         *      okText 确认按钮名称
         *      param 自定义参数
         *      type 类型【info、success、warning、error】
         *  ]
         * @param okCallback 确认回调
         */
        alert(options = {}, okCallback) {
            const config = {
                // 标题
                title: options.title ? options.title : '',
                // 内容
                content: options.content ? options.content : '',
                // 确认按钮
                okText: options.okText ? options.okText : '确认',
                onOk: () => {
                    if (okCallback) {
                        let param = options.param ? options.param : '';
                        okCallback(param);
                    }
                },
            };

            switch (options.type) {
                case 'info':
                    this.$info(config);
                    break;
                case 'success':
                    this.$success(config);
                    break;
                case 'warning':
                    this.$warning(config);
                    break;
                case 'error':
                default:
                    this.$error(config);
                    break;
            }
        },

        /**
         * 自动消失弹框【提示框】
         * @param options [
         *      content 内容
         *      duration 关闭时间【单位秒. 0则不自动关闭】
         *      param 自定义参数
         *      type 类型【info、success、warning、error、warn、loading】
         *  ]
         * @param callback 确认回调
         */
        toast(options = {}, callback) {
            const config = {
                // 内容
                content: options.content ? options.content : '',
                // 自动关闭的延时，单位秒。设为 0 时不自动关闭
                duration: options.duration ? options.duration : 2,
                onClose: () => {
                    if (callback) {
                        let param = options.param ? options.param : '';
                        callback(param);
                    }
                },
            };

            switch (options.type) {
                case 'info':
                    this.$message.info(config.content, config.duration, config.onClose);
                    break;
                case 'error':
                    this.$message.error(config.content, config.duration, config.onClose);
                    break;
                case 'warning':
                    this.$message.warning(config.content, config.duration, config.onClose);
                    break;
                case 'warn':
                    this.$message.warn(config.content, config.duration, config.onClose);
                    break;
                case 'loading':
                    this.$message.loading(config.content, config.duration, config.onClose);
                    break;
                case 'success':
                default:
                    this.$message.success(config.content, config.duration, config.onClose);
                    break;
            }
        },

        /**
         * 确认弹框
         * @param options [
         *      title 标题
         *      content 内容
         *      okText 确认按钮名称
         *      cancelText 取消按钮名称
         *      param 自定义参数
         *  ]
         * @param okCallback 确认回调
         * @param cancelCallback 取消回调
         */
        confirm(options = {}, okCallback, cancelCallback) {
            const config = {
                // 标题
                title: options.title ? options.title : '',
                // 内容
                content: options.content ? options.content : '',
                // 确认按钮
                okText: options.okText ? options.okText : '确认',
                // 取消按钮
                cancelText: options.cancelText ? options.cancelText : '取消',
                onOk: () => {
                    if (okCallback) {
                        let param = options.param ? options.param : '';
                        okCallback(param);
                    }
                },
                onCancel: () => {
                    if (cancelCallback) {
                        let param = options.param ? options.param : '';
                        cancelCallback(param);
                    }
                }
            };

            this.$confirm(config);
        },
        /**
         * Http请求
         * @param url 请求地址
         * @param data 请求参数
         * @param successCallback 成功回调
         * @param errorCallback 错误回调
         * @param lock 是否加锁【0否、1Post、2Get】
         * @returns {boolean}
         */
        http(url, data, successCallback, errorCallback, lock = 0) {
            let lockTime = Date.now();

            // 锁机制
            if (lock) {
                if (store.state.apiLock) {// 已锁定，中止请求
                    return false;
                } else {// 未锁定，加锁
                    store.commit("CHANGE_APILOCK", true);
                }
            }

            let params = this.formatParams(data);

            axios({
                method: 'post',
                url: process.env.VUE_APP_WS + url,
                data: qs.stringify(params),
            }).then( res => {
                let diffTime = 0;
                // 解锁
                if (lock) {
                    store.commit("CHANGE_APILOCK", false);
                }

                // 错误处理
                if (res.data.msgcode != 200) {
                    // 1000token失效, 1001账户被关闭或不存在
                    if (res.data.msgcode == 1000 || res.data.msgcode == 1001) {
                        this.logoutRemoveCookie();

                        let redirect_url = this.$route.query.redirect_url ? this.$route.query.redirect_url : this.$route.fullPath;
                        this.$router.push('/manager/login?redirect_url=' + encodeURIComponent(redirect_url));
                        return true;
                    }

                    // 错误回调
                    if (errorCallback && errorCallback(res.data) === false) {
                        return true;
                    }

                    // 抛出异常
                    this.alert({ content: res.data.msgstr ? res.data.msgstr : res.data.info});
                    return true;
                }

                if (successCallback) {
                    successCallback(res.data);
                }
            }).catch(function (error) {
                // 解锁
                if (lock) {
                    store.commit("CHANGE_APILOCK", false);
                    // this.$toast.clear();
                }

                console.log(error);
            });
        },
        /**
         * 上传图片
         * @param url 请求地址
         * @param options 请求参数 {
         *      site: '',
         *      module: '',
         *      imgurl: 1,是否返回图片地址 1是 0否
         *      filestatus: 0,是否上传正式目录 1是 0否
         *      max: '', 大图尺寸  '600,600'
         *      min: '', 小图尺寸  '300,300'
         *      file: '', 文件流
         *      _FILES: [ 文件信息
         *          name: '', 文件名
         *          type: '', 文件类型
         *          size: '', 文件大小
         *      ]
         * }
         * @param successCallback 成功回调
         * @param lock 是否加锁
         * @returns {boolean}
         */
        httpImg(url, options = {}, successCallback, lock = 0) {
            // 锁机制
            if (lock) {
                if (store.state.apiLock) {// 已锁定，中止请求
                    return false;
                } else {// 未锁定，加锁
                    store.commit("CHANGE_APILOCK", true);
                    // this.toast({type: 'loading', duration: 0, loadingType: 'spinner'});
                }
            }

            if (!options.filedata) {
                return false;
            }

            let param = new FormData(); // 创建form对象

            // 其他参数
            param.append('site', options.site ? options.site : '');
            param.append('module', options.module ? options.module : '');
            param.append('filestatus', options.filestatus ? options.filestatus : 0);
            param.append('max', options.max ? options.max : '');
            param.append('min', options.min ? options.min : '');
            param.append('filedata', options.filedata ? JSON.stringify(options.filedata) : JSON.stringify({}));

            // 加密验证
            let authkey = new Date().getTime();
            let authcode = md5(authkey + '__' + authkey);
            param.append('authkey', authkey);
            param.append('authcode', authcode);

            axios({
                method: 'post',
                url: process.env.VUE_APP_UPLOAD + '/upload/index',
                data: param,
                headers: { 'Content-Type': 'multipart/form-data' },//添加请求头
            }).then(res => {
                // 解锁
                if (lock) {
                    store.commit("CHANGE_APILOCK", false);
                    this.$toast.clear();
                }

                // 错误处理
                if (res.data.code != 200) {
                    this.alert({ content: res.data.message });
                    return;
                }

                if (successCallback) {
                    successCallback(res.data);
                }
            }).catch(function (error) {
                // 解锁
                if (lock) {
                    store.commit("CHANGE_APILOCK", false);
                    // this.$toast.clear();
                }

                console.log(error);
                // stores.dispatch('hideloader');
            });

        },
        /**
         * 格式化请求参数
         * @param data
         * @returns {{info: string}}
         */
        formatParams(data) {
            if (!data) {
                data = {};
            }

            data = JSON.parse(JSON.stringify(data));
            data.terminal = process.env.VUE_APP_TERMINAL;
            data.identity = process.env.VUE_APP_IDENTITY;
            data.dateline = parseInt((new Date()).valueOf() / 1000);

            if (this.isLogin()) {
                data.muid = this.$cookies.get('muid');
                data.token = this.$cookies.get('token');
                data.accountid = this.$cookies.get('accountid');
            }

            return data;
        },
        /**
         * 是否登录
         * @returns {boolean}
         */
        isLogin() {
            return this.$cookies.get('muid') && this.$cookies.get('token');
        },
        /**
         * 登录
         * @param obj
         */
        loginSetCookie(obj) {
            this.setCookie('muid', obj.muid);
            this.setCookie('accountid', obj.accountid);
            this.setCookie('token', obj.token);
            this.setCookie('status', obj.status);
            this.setCookie('name', obj.name);
            this.setCookie('username', obj.username);
            this.setCookie('avatarurl', obj.avatarurl);
            this.setCookie('manager', obj.manager);
        },
        /**
         * 退出登录
         */
        logoutRemoveCookie() {
            this.removeCookie('muid');
            this.removeCookie('accountid');
            this.removeCookie('token');
            this.removeCookie('status');
            this.removeCookie('name');
            this.removeCookie('username');
            this.removeCookie('avatarurl');
            this.removeCookie('manager');
            //this.removeCookie('firstUrl');
        },
        check_limit(limit) {
            let manage = this.$cookies.get('manager');
            if (manage == 1) return true;

            let limits = this.getObjectLocalStorage('community_limit');
            return this.inArray(limit, limits) != -1;
        },
        /**
         * 设置cookie
         * @param name
         * @param value
         * @param expires
         * @param path
         * @param domain
         * @param secure
         */
        setCookie(name, value, expires = 864000, path='/', domain='', secure=false) {
            domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';

            this.$cookies.set(name, value, expires, path, domain, secure);
        },
        /**
         * 删除cookie
         * @param name
         * @param path
         * @param domain
         */
        removeCookie(name, path='/', domain='') {
            domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';
            this.$cookies.remove(name, path, domain);
        },
        /**
         * 设置localStorage【数据为对象】
         * @param name
         * @param value
         */
        setObjectLocalStorage(name, value) {
            this.$localStorage.set(name, JSON.stringify(value));
        },
        /**
         * 获取localStorage【数据为对象】
         * @param name
         * @returns {boolean}
         */
        getObjectLocalStorage(name) {
            let val = this.$localStorage.get(name);
            return val ? JSON.parse(val) : false;
        },
        /**
         * 日期格式化
         * @param date new Date()
         * @returns {{date_str: string, month: (*|number), year: number, day: any | number}}
         */
        getDateFormat(date) {
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0'+month : month;
            let day = date.getDate();
            day = day < 10 ? '0'+day : day;

            return {
                year: year,
                month: month,
                day: day,
                date_str: year+'-'+month+'-'+day,
            }
        },
        /**
         * 判断元素是否在数组中
         * @param val
         * @param arr
         * @returns {*}
         */
        inArray(val, arr) {
            /*if(typeof arr !== 'array') {
                return -1;
            }*/
            return $.inArray(val, arr);
        },
        /**
         * 获取终端信息【是否 android\ios\微信浏览器】
         * @returns {{isWeixin: boolean, isAndroid: boolean, isiOS: boolean}}
         */
        getTerminal() {
            let isAndroid = false;
            let isiOS = false;
            let isWeixin = false;

            let u = navigator.userAgent;
            let ua = u.toLowerCase();
            // Android终端
            isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1;
            if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {//android终端或者uc浏览器
                isAndroid = true
            }

            // Ios终端
            isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
                isiOS = true;
            }

            // 微信浏览器
            isWeixin = /micromessenger/.test(ua) ? true : false;

            return {
                isAndroid: isAndroid,
                isiOS: isiOS,
                isWeixin: isWeixin,
            }
        },
        /**
         * 监听列表窗口
         */
        listenListWindow() {
            $(window).resize(function () {
                $('.list-bottom').width($('.wrap-list-content').width());

            });
        },

        // 重置
        clear() {
            window.location.href = this.$route.path;
        },
        // 搜索
        search(param) {
            param.page = 1;
            param.pagesize = this.postData.pagesize;
            param.showfield = this.postData.showfield;
            param.random = Math.random();
            this.postData = param;

            // 重置选中项
            this.selectedRowKeys = [];

            this.$router.push({path: '', query: this.postData});
            this.getData();
        },
        // 显示设置
        setOption(param) {
            if(param.pagesize != this.postData.pagesize) {
                this.postData.page = 1;
            }
            this.postData.showfield = param.showfield;
            this.postData.pagesize = param.pagesize;
            this.postData.random = Math.random();

            this.$router.push({path: '', query: this.postData});
            this.getData();
        },
        // 全选or全反选
        onCheckAllChange (e) {
            if (e) {
                let ids = [];
                for (let i in this.data) {
                    ids.push(this.data[i][this.selectedKey]);
                }

                this.selectedRowKeys = ids;
                this.selectedRowKeysItem = this.data;
            } else {
                this.cancelSelectChange();
            }
        },
        // 取消选择
        cancelSelectChange() {
            this.selectedRowKeys = [];
            this.selectedRowKeysItem = [];
        },
        // 多选事件
        onSelectChange(ids, items) {
            this.selectedRowKeys = ids;
            this.selectedRowKeysItem = items;
        },
        // 翻页
        pageTo(e) {
            // 翻页
            this.postData.page = e;

            this.$router.push({path: '', query: this.postData});
            this.getData();
        },
        regMobile(mobile) {
            let reg = /^1[\d]{10}$/;
            return reg.test(mobile);
        },
        regIdcard(idcard) {
            let reg = /^[\d]{17}[\dx]$/i;
            return reg.test(idcard);
        },
        checkOrderNUM(rule, value, callback) {
            if (value >= 0 && value <= 100) {
                callback();
            } else {
                callback(true);
            }
        },
        checkMobile(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback();
                return;
            };

            if(this.regMobile(value) === false) {
                callback(true);
            } else {
                callback();
            }
        },
        requiredMobile(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback(true);
                return;
            };

            if(this.regMobile(value) === false) {
                callback(true);
            } else {
                callback();
            }
        },
        checkIdcard(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback();
                return;
            };

            if(this.regIdcard(value) === false) {
                callback(true);
            } else {
                callback();
            }
        },
        requiredIdcard(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback(true);
                return;
            };

            if(this.regIdcard(value) === false) {
                callback(true);
            } else {
                callback();
            }
        },
        checkMoney(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback();
            };

            let validate = /^\-?((\d*)|(\d*\.\d{1,2}))$/.test(value);
            if(validate === false) {
                callback(true);
            } else {
                callback();
            }
        },
        requiredMoney(rule, value, callback) {
            if (value == null || value == '' || typeof value == 'undefined') {
                callback(true);
                return;
            };

            let validate = /^\-?((\d*)|(\d*\.\d{1,2}))$/.test(value);
            if(validate === false) {
                callback(true);
            } else {
                callback();
            }
        },
    }
};
