<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
        :confirm-loading="confirmLoading"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <div class="import-tips">
                    <a-alert type="warning">
                        <p slot="message">导入说明：请下载<a href="javascript:void(0);" @click="download">{{ desc }}</a>，按模板提示修改本地文件后再上传文件。
                        </p>
                    </a-alert>
                </div>
                <div class="import-content-column bomb-import-file">
                    <div class="import-content-list">
                        <div class="content-select">
                            <a-upload :multiple="false" :beforeUpload="beforeUpload" :fileList="fileList"
                                      @change="handleChangeFile">
                                <a-button>
                                    <i class="iconfont icontop_import"></i>上传文件
                                </a-button>
                            </a-upload>
                            <div class="content-remind">
                                <p>一次最多上传2000行，超出部分请分次上传。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--导入结果-->
        <exportRes
            :visible="showModal"
            :thead="exportData.thead"
            :error_data="exportData.error_data"
            :success_num="exportData.success_num"
            :error_num="exportData.error_num"
            @okFun="okFunRes"
            @cancelFun="okFunRes"
            @changeModal="changeModal"
        >
        </exportRes>
    </a-modal>
</template>

<script>
import {Icon} from 'ant-design-vue';

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});
import exportRes from '@/components/modal/uploadResult';

export default {
    components: {
        IconFont,
        exportRes,
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '导入数据'
        },
        desc: {
            type: String,
            default: '数据模板'
        },
        url: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {},
        },
        tplinfo: {// 模板
            type: [Object, Array],
            default: []
        },
    },
    data() {
        return {
            showModal: false,// 导出结果
            fileList: [],
            exportData: {
                thead: [],
                error_data: [],
                success_num: 0,
                error_num: 0,
            },
            confirmLoading: false,
        };
    },
    computed: {},
    watch: {
        // 实现双向绑定
        visible(val) {
            if (val === false) {
                this.fileList = [];
                this.confirmLoading = false;
            } else {
                this.getData();
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible'
    },
    methods: {
        getData() {

        },
        okFun() {
            this.confirmLoading = true;

            if (this.fileList.length === 0) {

                this.confirmLoading = false;

                this.alert({content: '请上传文件'});
                return false;
            }

            let that = this;
            let reader = new FileReader();
            reader.readAsDataURL(this.fileList[0].originFileObj);
            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {
                let param = {};
                for (let i in that.data) {
                    param[i] = that.data[i];
                }

                param.filedata = this.result.split(',')[1];

                that.http(that.url, param, (res) => {
                    that.showModal = true;
                    that.exportData.thead = res.data.header;
                    that.exportData.error_data = res.data.errordata;
                    that.exportData.error_num = res.data.error;
                    that.exportData.success_num = res.data.success;
                    that.closeModal();
                });
            };
        },
        beforeUpload(file) {
            return false;
        },
        handleChangeFile(e) {
            if (Array.isArray(e)) {
                this.fileList = e;
                return e;
            }
            // 只允许上传一个文件
            if (e.fileList) {
                let len = e.fileList.length;
                if (len >= 1) {
                    e.fileList = [e.fileList[len - 1]];
                }
            }
            this.fileList = e.fileList;
        },
        download() {
            require.ensure([], () => {
                const {export_json_to_excel} = require('../../assets/excel/Export2Excel');
                let data = [];
                let merge = [{"s": {"r": 0, "c": 0}, "e": {"r": 0, "c": this.tplinfo.length - 1}}];
                let style = {'A1': {alignment: {vertical: 'center', horizontal: 'center'}}};
                let title = this.desc;

                let required = [];

                for (let i in this.tplinfo) {
                    data.push(this.tplinfo[i].finame);

                    if (this.tplinfo[i].required === '1') {
                        required.push(this.tplinfo[i].finame);
                    }
                }

                // console.log(required);

                let th = '';

                if (required.length > 0) {
                    th = title + "\n\n" + '必填项：' + required.join('、');
                }

                let thead = [th];

                export_json_to_excel(thead, [data], title, merge, style);
            })
        },
        okFunRes() {
            this.$emit("confirm");
        },
        closeModal() {
            this.$emit('changeVisible', false);
        },
        cancelFun() {
            this.closeModal();
        },
        changeModal(val) {
            this.showModal = val;
        }
    }
}
</script>

<style>
.content-select {
    padding-top: 40px;
    margin: 0 auto;
    text-align: center;
}

.content-select .content-remind {
    padding-top: 20px;
}
</style>