<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="入职时间"
                    >
                        <a-date-picker
                            placeholder="入职时间"
                            v-decorator="['jobtime', {rules: [{ required: true, message: '请选择入职时间' }]}]"
                        />
                    </a-form-item>

                    <a-form-item
                        label="供应商"
                        v-if="show"
                    >
                        <a-auto-complete
                            class="global-search"
                            size="large"
                            style="width: 100%"
                            placeholder="请输入供应商名称关键词"
                            option-label-prop="title"
                            @select="autoSelect"
                            @focus="autoSearch"
                            @search="autoSearch"
                            v-decorator="['supid', {rules: [{ required: true, message: '请选择供应商' }]}]"
                        >
                            <template slot="dataSource">
                                <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                    没有找到供应商，去<a href="/supplier/create" target="_blank">新增供应商</a>
                                </a-select-option>
                                <a-select-option v-else v-for="item in dataSource" :key="item.supid + '@' + item.muid"
                                        :title="item.supname">
                                    {{ item.supname }}
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-item>

                    <a-form-item
                        label="客户经理"
                        v-if="show"
                    >
                        <a-select
                            placeholder="请选择员工的客户经理"
                            v-decorator="['cmuid', {rules: [{ required: true, message: '请选择员工的客户经理' }]}]"
                        >
                            <a-select-option v-for="(item, key) in config.managers" :key="item[0]" :value="item[0]" :disabled="item[0] > 0 ? false : true">{{ item[1] }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item
                        label="招聘政策"
                    >
                        <a-textarea
                            v-decorator="['policy', {rules: [{ required: false, max: 256, message: '招聘政策不超过256个字符' }], initialValue: ''}]"
                            placeholder="请填写招聘政策"
                            :auto-size="{ minRows: 2 }"
                        />
                    </a-form-item>

                    <a-form-item
                        label="政策有效期"
                    >
                        <a-date-picker
                            placeholder="政策有效期"
                            v-decorator="['expiretime', {rules: [{ required: false, message: '请选择政策有效期' }]}]"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        chid: {
            type: Array,
            default: '',
        },
        rcname: {
            type: String,
            default: '',
        },
        cmuid: {
            type: String,
            default: '',
        },
        supid: {
            type: String,
            default: '',
        },
        supname: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '确认入职',
            form: this.$form.createForm(this),
            config: {},
            postData: {
                keyword: this.supname,
            },
            data: {
                supid: 0,
            },
            dataSource: {},
            show: false,
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
                this.show = false;
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        autoSearch (value) {
            this.postData.keyword = value

            this.http('/manage/supplier/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)

            let item = value.split('@');
            this.data.supid = item[0];

            if (this.cmuid === '' || this.cmuid === '0') {
                this.form.setFieldsValue({
                    cmuid: item[1],
                });
            }
        },
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {

            if (this.chid.length == 1) {
                this.show = true;
            }

            this.$nextTick(() => {
                if (this.cmuid !== '' && this.cmuid !== '0') {
                    this.form.setFieldsValue({
                        cmuid: this.cmuid,
                    });
                }

                if (this.supid !== '' && this.supid !== '0') {
                    this.data.supid = this.supid;

                    this.form.setFieldsValue({
                        supid: this.supname,
                    });
                }
            });

            if (this.chid) {
                this.title = '确认入职 ' + this.rcname;

                let data = {};
                this.http('/manage/manager/managers', data, (res) => {
                    this.config = res.config;
                }, null, 1);
            }
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/empcheckin/job';
                        let data = values;

                        data.chid = this.chid;
                        data.supid = this.data.supid;
                        data.jobtime = data.jobtime ? data.jobtime.format('YYYY-MM-DD') : '';
                        data.expiretime = data.expiretime ? data.expiretime.format('YYYY-MM-DD') : '';

                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
