<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-list-content">

            <search-screen
                :checkData="postData"
                :set-condition="{
                    pagesize: setCondition.pagesizes,
                    field: setCondition.fields,
                }"
                :config="config"
                :scenario="scenario"
                @exportRes="exportRes(0)"
                @setOption="setOption"
                @clear="clear"
                @search="search"
            >
            </search-screen>

            <div class="hro-table hro-table-only">
                <hro-table
                    :columns="columns"
                    :dataSource="data"
                    :loading="loading"
                    :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                    :scroll="scroll"
                    :rowKey="selectedKey"
                    @actionFun="actionFun"
                >

                </hro-table>
            </div>
        </div>

        <list-bottom
            :page="postData.page"
            :pagesize="postData.pagesize"
            :total="total"
            :data="data"
            :selectedRowKeys="selectedRowKeys"
            :condition="{
                checkall: true,
            }"
            :scenario="scenario"
            @checked="onCheckAllChange"
            @page="pageTo"
            @optionFun="bottomOptionFun"
        >
        </list-bottom>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import searchScreen from '@/components/list/searchScreen';
import listBottom from '@/components/list/listBottom';
import hroTable from '@/components/list/table';
import listHeader from '@/components/list/listHeader';

export default {
    components: {
        listBottom,
        hroTable,
        searchScreen,
        listHeader,
    },
    data() {
        return {
            title: '统计数据',
            scenario: 'rcanalysisList', // 页面标识
            selectedRowKeys: [], // 选中行的key
            selectedRowKeysItem: [], // 选中行的item
            selectedKey: 'muid', // 数据主键字段（唯一）
            loading: false,// 请求
            scroll: null, //滚动
            data: [],
            setCondition: {
                fields: [],// 显示字段
                pagesizes: {},// 每页显示数筛选条件
            },
            postData: {
                sdate: moment().startOf('month').format("YYYY-MM-DD"),
                edate: '',
                showfield: [], // 显示字段
            },
            exportData: {// 导出数据
                page: 1,
                pagesize: 1000,
                isRequest: false,
                thead: [],
                data: [],
            },
            total: 0,
            config: {},
            showModal: false, // 弹框
        }
    },
    computed: {
        columns() {
            // 处理显示字段
            let columns = [];
            for (let i in this.setCondition.fields) {
                let index = this.inArray(this.setCondition.fields[i].key, this.postData.showfield);
                if (index > -1) {
                    let item = {
                        title: this.setCondition.fields[i].text,
                        dataIndex: this.setCondition.fields[i].key,
                    };

                    if (i == 0 && this.setCondition.fields[i].default == 1) {
                        item.fixed = 'left';
                        item.width = 100;
                    }

                    if (this.setCondition.fields[i].key == 'addnum') {
                        item.scopedSlots = {customRender: 'rcanalysis_addnum'};
                    }
                    if (this.setCondition.fields[i].key == 'visitnum') {
                        item.scopedSlots = {customRender: 'rcanalysis_visitnum'};
                    }
                    if (this.setCondition.fields[i].key == 'interviewnum') {
                        item.scopedSlots = {customRender: 'rcanalysis_interviewnum'};
                    }
                    if (this.setCondition.fields[i].key == 'jobnum') {
                        item.scopedSlots = {customRender: 'rcanalysis_jobnum'};
                    }
                    if (this.setCondition.fields[i].key == 'checkinnum') {
                        item.scopedSlots = {customRender: 'rcanalysis_checkinnum'};
                    }

                    columns.push(item);
                }
            }

            let len = columns.length;
            let x = 300 + (len - 2) * 150;
            let table_width = $('.hro-table').width();
            this.scroll = x > table_width ? {x: x} : null;

            return columns;
        }
    },
    watch: {
        '$store.state.showLayoutMenu'(val) {
            $('.list-bottom').width($('.wrap-list-content').width());
        },
    },
    created() {
        // 当浏览器大小变化时
        this.listenListWindow();

        // 初始化postData
        this.setData();
        // 请求数据
        this.getData();
    },
    methods: {
        getData() {
            // 初始化批量操作
            this.selectedRowKeys = [];

            // 加载等待
            this.loading = true;
            // 路由变化
            // this.changeRouter();
            this.http('/manage/rcanalysis/list', this.postData, (res) => {
                this.loading = false;

                // 数据渲染
                this.setCondition.fields = res['_fields'];
                this.setCondition.pagesizes = res['_pagesizes'];
                this.data = res.list.data;
                this.config = res.config;

                // 初始化底部宽度
                this.$nextTick(() => {
                    $('.list-bottom').width($('.wrap-list-content').width());
                });
            }, null, 2);
        },
        // 设置参数
        setData() {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) continue;

                if (i === 'showfield') {
                    this.postData[i] = typeof this.$route.query[i] === 'string' ? [this.$route.query[i]] : this.$route.query[i];
                } else {
                    this.postData[i] = this.$route.query[i];
                }
            }

            // 初始化显示字段
            if (this.$route.query['showfield'] === undefined) {
                this.postData['showfield'] = ['muname', 'addnum', 'visitnum', 'interviewnum', 'jobnum', 'checkinnum'];
            }
        },
        // 路由监听
        changeRouter() {
            this.$router.push({path: '', query: this.postData});
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                default:
                    break;
            }
        },
        // 列表操作
        actionFun(e) {
            switch (e.op) {
                case 'add':
                    this.$router.push('/rencai/list?smuid=' + e[this.selectedKey] + '&sdate=' + this.postData.sdate + '&edate=' + this.postData.edate);
                    break;
                case 'visit':
                    this.$router.push('/rcvisit/list?smuid=' + e[this.selectedKey] + '&sdate=' + this.postData.sdate + '&edate=' + this.postData.edate);
                    break;
                case 'interview':
                    this.$router.push('/rcinterview/list?smuid=' + e[this.selectedKey] + '&sdate=' + this.postData.sdate + '&edate=' + this.postData.edate);
                    break;
                case 'job':
                    this.$router.push('/rcjob/list?smuid=' + e[this.selectedKey] + '&sdate=' + this.postData.sdate + '&edate=' + this.postData.edate);
                    break;
                case 'checkin':
                    this.$router.push('/rcjob/list?smuid=' + e[this.selectedKey] + '&status=1&&sdate=' + this.postData.sdate + '&edate=' + this.postData.edate);
                    break;
                default:
                    break;
            }
        },
        // 底部操作
        bottomOptionFun(e) {
            if (this.selectedRowKeys.length === 0) {
                return false;
            }

            switch (e.op) {
                default:
                    break;
            }
        },
        // 导出结果
        exportRes(type = 0) {
            if (this.exportData.isRequest === true && type === 0) {
                return false;
            }
            require.ensure([], () => {
                const {export_json_to_excel} = require('../../assets/excel/Export2Excel');
                let params = {};
                for (var i in this.postData) {
                    params[i] = this.postData[i];
                }
                params.page = this.exportData.page;
                params.pagesize = this.exportData.pagesize;
                this.http('/manage/rcanalysis/list', params, (res) => {
                    let col_len = this.columns.length;
                    // 头部信息
                    if (params.page === 1) {
                        for (let i = 0; i < col_len; i++) {
                            this.exportData.thead.push(this.columns[i].title);
                        }
                    }
                    for (let key in res.list.data) {
                        let row_data = [];
                        for (let i = 0; i < col_len; i++) {
                            let index = this.columns[i].dataIndex;
                            if (res.list.data[key][index] !== undefined) {
                                row_data.push(res.list.data[key][index]);
                            } else {
                                row_data.push(res.list.data[key].attrs[index]);
                            }
                        }
                        this.exportData.data.push(row_data);
                    }
                    let maxPage = parseInt(this.total / 1000) + 1;
                    if (params.page === maxPage) {
                        let thead = JSON.parse(JSON.stringify(this.exportData.thead));
                        let data = JSON.parse(JSON.stringify(this.exportData.data));
                        // 初始化导出数据
                        this.exportData.page = 1;
                        this.exportData.thead = [];
                        this.exportData.data = [];
                        this.exportData.isRequest = false;
                        // 导出
                        export_json_to_excel(thead, data, '统计数据');
                    } else {
                        this.exportData.page++;
                        this.exportRes(1);
                    }
                }, null, 1);
            })
        }
    },
}
</script>


<style lang="less" src="../../assets/css/list/list.less"></style>