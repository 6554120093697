<template>
    <div class="wrap">
        <listHeader :title="title" :scenario="scenario" @optionFun="headerOptionFun"></listHeader>

        <div class="wrap-form-add">
            <div class="wrap-form-list">

                <a-tabs type="card" @change="tabChange" default-active-key="info">
                    <a-tab-pane key="info" tab="员工信息"></a-tab-pane>
                    <a-tab-pane key="contract" tab="合同信息"></a-tab-pane>
                    <a-tab-pane key="policy" tab="政策信息"></a-tab-pane>
                </a-tabs>

                <div class="wrap-form-info">
                    <a-row>

                        <a-divider orientation="left">
                            基本信息
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="姓名"
                                    :colon=false
                                    v-bind="formItemLayout"
                                    required
                                >
                                    <a-input placeholder="请输入员工姓名"
                                             v-decorator="['empname', {rules: [{ required: true, max: 16, message: '请输入员工姓名, 最大16个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="身份证"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入员工的身份证号"
                                             v-decorator="['idcard', {rules: [{ required: true, validator: checkIdcard, message: '请输入员工的身份证号' }], initialValue: ''}]"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="手机"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入员工的手机号"
                                             v-decorator="['mobile', {rules: [{ required: true, validator: checkMobile, message: '请输入员工的手机号, 最大11个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="入职日期"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker
                                        placeholder="入职日期"
                                        v-decorator="['jobtime', {rules: [{ required: true, message: '请选择入职日期' }]}]"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="离职日期"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker
                                        placeholder="离职日期"
                                        v-decorator="['quittime', {rules: [{ required: false, message: '请选择离职日期' }]}]"
                                    />
                                </a-form-item>

                                <a-form-item
                                    label="员工编号"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="不输入员工编号，系统将自动生成"
                                             v-decorator="['empno', {rules: [{ required: false, max: 64, message: '员工编号最大64个字符' }], initialValue: ''}]"/>
                                </a-form-item>
                            </a-form>
                        </a-col>


                        <a-divider orientation="left">
                            工资信息
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">

                                <a-form-item
                                    label="银行"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-select
                                        placeholder="请选择银行"
                                        v-decorator="['bankid', {rules: [{ required: false, message: '请选择银行' }]}]"
                                    >
                                        <a-select-option v-for="(item, key) in config.banks" :key="key" :value="key">{{ item }}</a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    label="卡号"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入银行卡号"
                                             v-decorator="['bankno', {rules: [{ required: false, max: 32, message: '请输入银行卡号, 最大32个字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="开户行"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入开户行"
                                             v-decorator="['bankaddr', {rules: [{ required: false, max: 64, message: '请输入开户行, 最大64个字符' }], initialValue: ''}]"/>
                                </a-form-item>
                            </a-form>
                        </a-col>

                        <a-divider orientation="left">
                            社保、公积金
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">


                                <a-form-item
                                    label="社保基数"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入社保基数"
                                             v-decorator="['shebao', {rules: [{ required: false, validator: checkMoney, message: '请输入社保基数' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="公积金基数"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入公积金基数"
                                             v-decorator="['zhufang', {rules: [{ required: false, validator: checkMoney, message: '请输入公积金基数' }], initialValue: ''}]"/>
                                </a-form-item>

                            </a-form>
                        </a-col>

                        <a-divider orientation="left" v-if="showContract">
                            合同信息
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14" v-if="showContract">
                            <a-form :form="form">

                                <a-form-item
                                    label="合同编号"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-input placeholder="请输入合同编号"
                                             v-decorator="['cono', {rules: [{ required: false, max: 64, message: '请输入合同编号,最大64字符' }], initialValue: ''}]"/>
                                </a-form-item>

                                <a-form-item
                                    label="到期日期"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-date-picker
                                        placeholder="到期日期"
                                        v-decorator="['expiretime', {rules: [{ required: false, message: '请选择到期日期' }]}]"
                                    />
                                </a-form-item>

                            </a-form>
                        </a-col>

                        <a-divider orientation="left">
                            供应商
                        </a-divider>

                        <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                            <a-form :form="form">


                                <a-form-item
                                    label="供应商"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-auto-complete
                                        class="global-search"
                                        size="large"
                                        style="width: 100%"
                                        placeholder="请输入供应商名称关键词"
                                        option-label-prop="title"
                                        @select="autoSelect"
                                        @focus="autoSearch"
                                        @search="autoSearch"
                                        v-decorator="['supid', {rules: [{ required: true, message: '请选择供应商' }]}]"
                                    >
                                        <template slot="dataSource">
                                            <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                                没有找到供应商，去<a href="/supplier/create" target="_blank">新增供应商</a>
                                            </a-select-option>
                                            <a-select-option v-else v-for="item in dataSource" :key="item.supid"
                                                             :title="item.supname">
                                                {{ item.supname }}
                                            </a-select-option>
                                        </template>
                                    </a-auto-complete>
                                </a-form-item>

                                <a-form-item
                                    label="客户经理"
                                    :colon=false
                                    v-bind="formItemLayout"
                                >
                                    <a-select
                                        placeholder="请选择员工的客户经理"
                                        v-decorator="['cmuid', {rules: [{ required: true, message: '请选择员工的客户经理' }]}]"
                                    >
                                        <a-select-option v-for="(item, key) in config.managers" :key="item[0]" :value="item[0]" :disabled="item[0] > 0 ? false : true">{{ item[1] }}</a-select-option>
                                    </a-select>
                                </a-form-item>

                                <a-form-item
                                    v-bind="formBtnLayout"
                                >
                                    <a-button type="primary" @click="create">保存简历</a-button>

                                    <a-button @click="$router.go(-1)" style="margin-left:10px;">返回</a-button>
                                </a-form-item>

                            </a-form>
                        </a-col>

                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import listHeader from '@/components/list/listHeader';
import moment from 'moment'

export default {
    components: {
        listHeader,
    },
    data () {
        return {
            title: '编辑员工',
            scenario: 'employeeEdit', // 页面标识
            visible: false, // 是否显示图片src
            loading: false, // 显示上传中
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
            formBtnLayout: {
                wrapperCol: {
                    span: 19,
                    offset: 5,
                },
            },
            form: this.$form.createForm(this),
            postData: {
                'empid': '',
                'keyword': '',
            },
            dataSource: [],
            data: {},
            config: {},
            tags: {},
            dateFormat: 'YYYY-MM-DD',
            showModal: false,
            showContract: false,
        }
    },
    Watch: {},
    created () {
        this.setData()
        this.getData()
    },
    methods: {
        autoSearch (value) {
            this.postData.keyword = value

            this.http('/manage/supplier/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.supid = value;
        },
        tabChange(key) {
            switch (key) {
                case 'info': // 基本信息
                    this.$router.push('/employee/edit?empid=' + this.postData.empid);
                    break
                case 'contract': // 合同
                    this.$router.push('/empcontract/list-employee?empid=' + this.postData.empid);
                    break
                case 'policy': // 政策
                    this.$router.push('/emppolicy/list-policy?empid=' + this.postData.empid);
                    break
                default:
                    break
            }
        },
        setData () {
            for (let i in this.postData) {
                if (this.$route.query[i] === undefined) {
                    continue
                }
                this.postData[i] = this.$route.query[i]
            }
        },
        getData () {
            // 查询数据
            this.http('/manage/employee/info', this.postData, (res) => {
                this.data = res.data;
                this.config = res.config;

                // 供应商处理
                let supplier = {};
                supplier.supid = res.data.supid;
                supplier.supname = res.data.supname;

                this.dataSource.push(supplier);

                this.$nextTick((r) => {
                    this.form.setFieldsValue({
                        empname: res.data.empname,
                        mobile: res.data.mobile,
                        idcard: res.data.idcard,
                        jobtime: res.data.jobtime_str ? moment(res.data.jobtime_str) : null,
                        quittime: res.data.quittime_str ? moment(res.data.quittime_str) : null,
                        empno: res.data.empno,

                        bankno: res.data.bankno,
                        bankaddr: res.data.bankaddr,

                        shebao: res.data.shebao,
                        zhufang: res.data.zhufang,

                        supid: res.data.supid,
                        cmuid: res.data.muid,
                    });

                    if (res.data.bankid != 0) {
                        this.form.setFieldsValue({
                            bankid: res.data.bankid,
                        });
                    }

                    if (!res.data.contract) {
                        this.showContract = true;
                    }
                });

            }, null, 2)
        },
        create () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = url = '/manage/employee/edit'

                        values.empid = this.postData.empid;
                        values.supid = this.data.supid;
                        values.jobtime = values.jobtime ? values.jobtime.format('YYYY-MM-DD') : ''
                        values.quittime = values.quittime ? values.quittime.format('YYYY-MM-DD') : '';
                        values.expiretime = values.expiretime ? values.expiretime.format('YYYY-MM-DD') : '';

                        this.http(url, values, (res) => {
                            this.toast({ content: '保存成功' }, () => {
                                this.getData();
                            })
                        }, null, 1)
                    }
                },
            )
        },
        // 头部操作
        headerOptionFun(e) {
            switch (e.op) {
                default:
                    break;
            }
        },
    },
}
</script>

<style lang="less" src="../../assets/css/add/add.less"></style>
