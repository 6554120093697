// 岗位
import login from '../views/manager/login';
import register from '../views/manager/register';

export default [
    {
        path: '/manager/login',
        meta: {
            metaInfo: {
                title: "登录 - HelloHRO人资源外包管理云平台",
                keywords: "",
                description: ""
            },
            forbidLogin: true,
        },
        component: login,
    },
    {
        path: '/manager/register',
        meta: {
            metaInfo: {
                title: "注册体验会员 - HelloHRO人资源外包管理云平台",
                keywords: "",
                description: ""
            },
            forbidLogin: true,
        },
        component: register,
    },
];
