import layout from '@/components/common/layout';
import interviewList from '../views/rcinterview/list';
import interviewRencai from '../views/rcinterview/interview';
import interviewRemindList from '../views/rcinterview/remindlist';

export default [
    {
        path: '/rcinterview',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部面试",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcinterview_list',// 子选项
                    }
                },
                component: interviewList,
            },
            {
                path: 'list-interview',
                meta: {
                    metaInfo: {
                        title: "面试记录",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcinterview_list',// 子选项
                    }
                },
                component: interviewRencai,
            },
            {
                path: 'list-remind',
                meta: {
                    metaInfo: {
                        title: "面试提醒",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcinterview_remind',// 子选项
                    }
                },
                component: interviewRemindList,
            },
        ]
    },
];
