<template>
    <a-modal
        title="简历附件"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="附件"
                        required
                    >
                        <a-upload
                            name="avatar"
                            :file-list="fileList"
                            :customRequest="uploadImg"
                        >
                            <a-button> <a-icon type="upload" /> 选择简历附件 </a-button>
                        </a-upload>
                    </a-form-item>

                    <a-form-item
                        label="描述"
                    >
                        <a-input placeholder="请输入展示图描述"
                                 v-decorator="['title', {rules: [{ required: false, max: 64, message: '请输入展示图描述，最大64字符' }], initialValue: ''}]"/>
                    </a-form-item>

                    <a-form-item
                        label="排序"
                    >
                        <a-input placeholder="请填写排序值 0~100"
                                 v-decorator="['ordernum', {rules: [{ required: false, validator: checkOrderNUM, message: '请填写排序值 0~100' }], initialValue: 100} ]"/>
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        rid: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            data: {
                attachment: '',
                fileStatus: 1,
            },
            fileList: [],
            form: this.$form.createForm(this),
            formItemLayout: {
                labelCol: {
                    span: 5,
                },
                wrapperCol: {
                    span: 19,
                },
            },
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
                this.fileList = [];
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        closeModal () {
            this.$emit('changeVisible', false)
        },
        uploadImg (e) {
            let that = this
            let reader = new FileReader()
            reader.readAsDataURL(e.file)

            //FileReader的onload句柄绑定回调函数
            reader.onload = function () {
                let filedata = this.result.split(',')[1]
                let data = {
                    site: 'hellohro',
                    module: 'resume',
                    filestatus: 0,
                    filedata: {
                        'tmp_name': filedata,
                        'type': e.file.type,
                        'name': e.file.name,
                        'size': e.file.size,
                    },
                }

                that.httpImg('', data, (res) => {
                    that.data.attachment = res.data.file
                    that.data.attachmentUrl = res.data.url
                    that.data.fileStatus = 0

                    let file = {};
                    file.uid = '-1';
                    file.name = res.data.file;
                    file.status = 'done';
                    file.url = res.data.url;

                    that.fileList = [file];
                })
            }
        },
        getData () {
            if (this.id) {
                // 初始化底部宽度
                this.$nextTick(() => {
                    let data = {}
                    data.id = this.id

                    this.http('/manage/rcresume/info', data, (res) => {

                        this.data.attachment = res.data.attachment;
                        this.data.attachmentUrl = res.data.attachmentUrl;
                        this.data.fileStatus = 1;

                        let file = {};
                        file.uid = '-1';
                        file.name = res.data.attachment;
                        file.status = 'done';
                        file.url = res.data.attachmentUrl;

                        this.fileList = [file];

                        this.form.setFieldsValue({
                            title: res.data.title,
                            ordernum: res.data.ordernum,
                        })
                    }, null, 1)

                })
            } else {
                this.data.attachment = '';
                this.data.attachmentUrl = '';
                this.data.fileStatus = 1;
                this.visi = false;
            }
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = ''
                        let data = values

                        if (!this.data.attachment) {
                            this.alert({title: '请上传简历附件', content: '请上传简历附件'})
                            return;
                        }

                        data.attachment = this.data.attachment;
                        data.fileStatus = this.data.fileStatus;

                        if (this.id) {
                            data.id = this.id
                            url = '/manage/rcresume/edit'
                        }
                        else {
                            data.rid = this.rid
                            url = '/manage/rcresume/save'
                        }
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')
                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
<style>
.avatar-uploader > .ant-upload {
    width: 200px;
    height: 128px;
}
.avatar-uploader img {
    width: 184px;
    height: 112px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
