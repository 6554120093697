import layout from '@/components/common/layout';
import jobList from '../views/rcjob/list';
import jobRencai from '../views/rcjob/job';
import jobRemindList from '../views/rcjob/remindlist';

export default [
    {
        path: '/rcjob',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部入职",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcjob_list',// 子选项
                    }
                },
                component: jobList,
            },
            {
                path: 'list-job',
                meta: {
                    metaInfo: {
                        title: "入职记录",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcjob_list',// 子选项
                    }
                },
                component: jobRencai,
            },
            {
                path: 'list-remind',
                meta: {
                    metaInfo: {
                        title: "入职提醒",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcjob_remind',// 子选项
                    }
                },
                component: jobRemindList,
            },
        ]
    },
];
