/**
 * 公用函数
 */
// import axios from 'axios';
// import qs from 'qs';
// import store from '../../store';
import VueCookies from "vue-cookies";



export default {
    /**
     * 登录
     * @param obj
     */
    /*loginSetCookie(obj) {
        this.setCookie('muid', obj.muid);
        this.setCookie('accountid', obj.accountid);
        this.setCookie('token', obj.token);
    },*/
    /**
     * 设置cookie
     * @param name
     * @param value
     * @param expires
     * @param path
     * @param domain
     * @param secure
     */
    /*setCookie(name, value, expires = 8640000, path='/', domain='', secure=false) {
        domain = process.env.VUE_APP_DOMAIN ? process.env.VUE_APP_DOMAIN : '';

        VueCookies.set(name, value, expires, path, domain, secure);
    },*/
    /**
     * 是否登录
     * @returns {boolean}
     */
    isLogin() {
        return VueCookies.get('muid') && VueCookies.get('token');
    },
};
