import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);


// 首页
import home from './home';

// 人才
import rencai from './rencai';
// 附件
import rcattachment from './rcattachment';
// 回访
import rcvisit from './rcvisit';
// 面试
import rcinterview from './rcinterview';
// 入职
import rcjob from './rcjob';
// 分析
import rcanalysis from './rcanalysis';

// 企业
import company from './company';
// 职位
import job from './job';
import jobtpl from './jobtpl';

// 员工
import empcheckin from './empcheckin';
import employee from './employee';
import empcontract from './empcontract';
import emppolicy from './emppolicy';

// 供应商
import supplier from './supplier';

// 账单
import bsalary from './bsalary';
import bshebao from './bshebao';
import bzhufang from './bzhufang';
import bbaoxian from './bbaoxian';
import brefund from './brefund';
import bmanage from './bmanage';
import bother from './bother';

// 对账
import bill from './bill';
import statement from './statement';
import stsdetail from './stsdetail';
import stkdetail from './stkdetail';

// 配置
import shebao from './shebao';
import zhufang from './zhufang';

// 新闻
import news from './news';
// 广告
import ad from './ad';
// 企业广告位
import qiye from './qiye';
// 用户
import manager from './manager';
// 个人中心
import center from './center';
// 错误
import error from './error';
// 系统设置
import system from "./system";

export default new Router({
    mode: 'history',
    routes: [
        ...home,
        ...rencai,
        ...rcattachment,
        ...rcvisit,
        ...rcinterview,
        ...rcjob,
        ...rcanalysis,
        ...company,
        ...job,
        ...jobtpl,
        ...empcheckin,
        ...employee,
        ...empcontract,
        ...emppolicy,
        ...supplier,
        ...bsalary,
        ...bshebao,
        ...bzhufang,
        ...bbaoxian,
        ...brefund,
        ...bmanage,
        ...bother,
        ...bill,
        ...statement,
        ...stsdetail,
        ...stkdetail,
        ...shebao,
        ...zhufang,
        ...news,
        ...ad,
        ...qiye,
        ...manager,
        ...center,
        ...error,
        ...system,
    ],
});
