import layout from '@/components/common/layout'
import qiyeList from '../views/qiye/list'
import qiyeCLassList from '../views/qiye/list-class'
import qiyeCreate from '../views/qiye/create'

export default [
    {
        path: '/qiye',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: '广告管理',
                        keywords: '',
                        description: '',
                    },
                    requireLogin: true,
                    menu: {
                        parent: '33',// 父选项
                        child: 'qiye_list',// 子选项
                    },
                },
                component: qiyeList,
            },
            {
                path: 'list-class',
                meta: {
                    metaInfo: {
                        title: '广告分类',
                        keywords: '',
                        description: '',
                    },
                    requireLogin: true,
                    menu: {
                        parent: '33',// 父选项
                        child: 'qiye_class_list',// 子选项
                    },
                },
                component: qiyeCLassList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: '新增广告',
                        keywords: '',
                        description: '',
                    },
                    requireLogin: true,
                    menu: {
                        parent: '33',// 父选项
                        child: 'qiye_list',// 子选项
                    },
                },
                component: qiyeCreate,
            },
        ],
    },
]
