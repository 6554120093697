import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiLock: false,// 默认未上锁
        showLayoutMenu: true,// 显示左侧菜单
        showMenu: true, // 显示主导航
        metaInfo: {// meta信息
            title: '',
            keywords: '',
            description: '',
        },
    },
    mutations: {
        CAHNGE_META_INFO (state, metaInfo) {
            if (metaInfo.title) {
                state.metaInfo.title = metaInfo.title
            }
            if (metaInfo.keywords) {
                state.metaInfo.keywords = metaInfo.keywords
            }
            if (metaInfo.description) {
                state.metaInfo.description = metaInfo.description
            }
        },
        CHANGE_APILOCK (state, locked) {
            state.apiLock = locked ? true : false
        },
    },
    actions: {},
})
