<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="公积金月"
                    >
                        <a-month-picker
                            placeholder="公积金月"
                            v-decorator="['month', {rules: [{ required: true, message: '请选择公积金月' }]}]"
                        />
                    </a-form-item>

                    <a-form-item
                        label="企业"
                    >
                        <a-auto-complete
                            class="global-search"
                            size="large"
                            style="width: 100%"
                            placeholder="请输入企业名称关键词"
                            option-label-prop="title"
                            @select="autoSelect"
                            @focus="autoSearch"
                            @search="autoSearch"
                            v-decorator="['cid', {rules: [{ required: true, message: '请选择企业' }]}]"
                        >
                            <template slot="dataSource">
                                <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                    没有找到企业，去<a href="/company/create" target="_blank">新增企业</a>
                                </a-select-option>
                                <a-select-option v-else v-for="item in dataSource" :key="item.cid"
                                                 :title="item.cname">
                                    {{ item.cname }}
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-item>

                    <a-form-item
                        label="职位"
                    >
                        <a-auto-complete
                            class="global-search"
                            size="large"
                            style="width: 100%"
                            placeholder="请输入职位名称关键词"
                            option-label-prop="title"
                            @select="autoSelectJob"
                            @focus="autoSearchJob"
                            @search="autoSearchJob"
                            v-decorator="['jid', {rules: [{ required: false, message: '请选择职位' }]}]"
                        >
                            <template slot="dataSource">
                                <a-select-option v-if="dataJob.length < 1" key="0" style="font-size: normal">
                                    没有找到职位，去<a href="/job/create" target="_blank">新增职位</a>
                                </a-select-option>
                                <a-select-option v-else v-for="item in dataJob" :key="item.jid"
                                                 :title="item.jobname">
                                    {{ item.jobname }}
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-item>

                    <a-form-item
                        label="公积金版本"
                    >
                        <a-auto-complete
                            class="global-search"
                            size="large"
                            style="width: 100%"
                            placeholder="请输入版本名称关键词"
                            option-label-prop="title"
                            @select="autoSelectZhufang"
                            @focus="autoSearchZhufang"
                            @search="autoSearchZhufang"
                            v-decorator="['zfid', {rules: [{ required: true, message: '请选择公积金版本' }]}]"
                        >
                            <template slot="dataSource">
                                <a-select-option v-if="dataZhufang.length < 1" key="0" style="font-size: normal">
                                    没有找到公积金版本，去<a href="/zhufang/create" target="_blank">新增公积金版本</a>
                                </a-select-option>
                                <a-select-option v-else v-for="item in dataZhufang" :key="item.zfid"
                                                 :title="item.zfname">
                                    {{ item.zfname }}
                                </a-select-option>
                            </template>
                        </a-auto-complete>
                    </a-form-item>

                    <a-form-item
                        label="备注"
                    >
                        <a-textarea
                            v-decorator="['remark', {rules: [{ required: false, max: 64, message: '备注不超过64个字符' }], initialValue: ''}]"
                            placeholder="请填写备注"
                            :auto-size="{ minRows: 2 }"
                        />
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            title: '新增公积金',
            form: this.$form.createForm(this),
            postData: {
                cid: 0,
                keyword: '',
            },
            config: {},
            dataSource: {},
            dataZhufang: {},
            dataJob: {},
            data: {
                cid: 0,
                jid: 0,
                zfid: 0,
            },
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        autoSearch (value) {
            if (value == '') {
                this.dataSource = {}
                return
            }

            this.postData.keyword = value

            this.http('/manage/company/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.cid = value;
            this.postData.cid = value;
        },
        autoSearchJob (value) {
            if (value == '') {
                this.dataJob = {}
                return
            }

            this.postData.keyword = value

            this.http('/manage/job/search', this.postData, (res) => {

                this.dataJob = res.list

            }, null, 2)
        },
        autoSelectJob (value) {
            // console.log(value)
            this.data.jid = value;
        },
        autoSearchZhufang (value) {
            if (value == '') {
                this.dataZhufang = {}
                return
            }

            this.postData.keyword = value

            this.http('/manage/zhufang/search', this.postData, (res) => {

                this.dataZhufang = res.list

            }, null, 2)
        },
        autoSelectZhufang (value) {
            // console.log(value)
            this.data.zfid = value
        },
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '';
                        let data = values;
                        data.cid = this.data.cid;
                        data.zfid = this.data.zfid;

                        if (!this.data.cid) {
                            this.alert({ title: '请选择企业', content: '请选择需要发放工资的企业' })
                            return
                        }

                        if (!this.data.zfid) {
                            this.alert({ title: '请选择公积金', content: '请选择公积金缴纳比例版本' })
                            return
                        }

                        url = '/manage/bzhufang/save'
                        data.month = data.month ? data.month.format('YYYY-MM') : ''
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
