<template>
    <div class="list-bottom">
        <!-- 注意！！！ 只有分页的时候加: page-right -->
        <div class="list-bottom-column flex align-items" :class="{'page-right': showPageClass}">
            <div class="list-bottom-left">
                <a-checkbox
                    :indeterminate="indeterminate"
                    :checked="checkAll"
                    :disabled="data && data.length === 0"
                    @change="onCheckAllChange"
                    v-if="condition && condition.checkall === true"
                >
                    全选
                </a-checkbox>

                <!--面试-->
                <template v-if="scenario === 'rcinterviewList'">
                    <a-button type="primary" @click="optionFun('job')">批量入职</a-button>
                    <a-button type="primary" @click="optionFun('cancel')">批量未通过</a-button>
                </template>

                <!--入职-->
                <template v-if="scenario === 'rcjobList'">
                    <a-button type="primary" @click="optionFun('checkin')">批量登记</a-button>
                    <a-button type="primary" @click="optionFun('cancel')">批量未通过</a-button>
                </template>

                <!--对账单-->
                <template v-if="scenario === 'statementList'">
                    <a-button v-if="check_limit('statement_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>

                <!--账单-->
                <template v-if="scenario === 'bsalaryList'">
                    <a-button v-if="check_limit('bsalary_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'bshebaoList'">
                    <a-button v-if="check_limit('bshebao_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'bzhufangList'">
                    <a-button v-if="check_limit('bzhufang_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'bbaoxianList'">
                    <a-button v-if="check_limit('bbaoxian_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'brefundList'">
                    <a-button v-if="check_limit('brefund_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'bmanageList'">
                    <a-button v-if="check_limit('bmanage_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>
                <template v-if="scenario === 'botherList'">
                    <a-button v-if="check_limit('bother_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>

                <!--员工-->
                <template v-if="scenario === 'employeeList'">
                    <a-button v-if="check_limit('employee_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                    <a-button type="primary" @click="optionFun('quit')">批量离职</a-button>
                </template>
                <template v-if="scenario === 'empCheckinList'">
                    <a-button type="primary" @click="optionFun('job')">批量登记</a-button>
                    <a-button type="primary" @click="optionFun('close')">批量未入职</a-button>
                    <a-button type="primary" @click="optionFun('customer')">设置供应商</a-button>
                </template>

                <!--人才-->
                <template v-if="scenario === 'rencaiMyList'">
                    <a-button type="primary" @click="optionFun('interview')">批量安排</a-button>
                    <a-button v-if="check_limit('rencai_kick')" type="primary" @click="optionFun('kick')">批量踢除</a-button>
                    <a-button v-if="check_limit('rencai_close')" type="primary" @click="optionFun('close')">批量删除</a-button>
                </template>

                <!--全部人才-->
                <template v-if="scenario === 'rencaiList'">
                    <a-button v-if="check_limit('rencai_assign')" type="primary" @click="optionFun('assign')">批量分配</a-button>
                </template>
            </div>
            <div class="page-number">
                <a-pagination
                    :current="parseInt(page)"
                    :pageSize="parseInt(pagesize)"
                    :total="parseInt(total)"
                    :show-total="(total, range) => `共${total}条数据`"
                    @change="pageTo"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        page: {
            type: [String, Number],
            default: 0,
        },
        pagesize: {
            type: [String, Number],
            default: 0,
        },
        total: {
            type: [String, Number],
            default: 0,
        },
        data: {
            type: [Object, Array],
            default: null,
        },
        selectedRowKeys: {
            type: [Object, Array],
            default: null,
        },
        condition: {
            type: [Object],
            default: null,
        },
        scenario: {
            type: [String],
            default: '',
        },
    },
    watch: {
        selectedRowKeys (val) {
            let len = val.length
            if (this.data) {
                if (len > 0 && this.data.length === len) {
                    this.checkAll = true
                    this.indeterminate = false
                }
                else {
                    this.checkAll = false
                    this.indeterminate = len == 0 ? false : true
                }
            }
        },
    },
    data () {
        return {
            checkAll: false,// 全选
            indeterminate: false,// 部分选中
        }
    },
    computed: {
        showPageClass () {

            //if(this.condition && this.condition.checkall && this.inArray(this.scenario, scenarioOptionArr) > -1) {
            return false
            //} else {
            //    return true;
            //}
        },
    },
    methods: {
        // 全选or全反选
        onCheckAllChange (e) {
            this.indeterminate = false
            if (e.target.checked) {
                this.checkAll = true
            }
            else {
                this.checkAll = false
            }
            this.$emit('checked', this.checkAll)
        },
        // 翻页
        pageTo (e) {
            this.$emit('page', e)
        },
        // 操作
        optionFun (op) {
            this.$emit('optionFun', { scenario: this.scenario, op: op })
        },
    },
}
</script>

<style lang="less">
@import '../../assets/css/init/var.less';

.list-bottom {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    margin-left: 24px;

    .list-bottom-column {
        padding: 0 24px;
        height: 62px;
        justify-content: space-between;
        border-left: 1px solid @border-color;
        border-right: 1px solid @border-color;
        border-top: 1px solid @border-color;
    }

    .page-right {
        justify-content: flex-end;
    }

    .list-bottom-left {
        button {
            margin-left: 16px;
        }
    }
}
</style>
