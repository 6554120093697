import layout from '@/components/common/layout';
import jobtplList from '../views/jobtpl/list';
import jobtplCreate from '../views/jobtpl/create';

export default [
    {
        path: '/jobtpl',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部职位表单",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobtplList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增职位表单",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'job_list',// 子选项
                    }
                },
                component: jobtplCreate,
            },
        ]
    },
];
