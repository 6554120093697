<template>
    <div id="app">
        <a-config-provider :locale="zh_CN">
            <router-view/>
        </a-config-provider>
    </div>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import moment from 'moment'
    import 'moment/locale/zh-cn'

    moment.locale('zh-cn')
    export default {
        components: {
        },
        data () {
            return {
                zh_CN,
            }
        },
        mounted () {
        },
    }
</script>


<style lang="less" src="./assets/css/init/common.less"></style>
<style src="./assets/fonts/iconfont.css"></style>
