<template>
    <div class="head-info flex align-items">
        <h3>{{ title }}</h3>
        <div class="global-operation">
            <!--配置-->
            <template v-if="scenario === 'centerIndex'">
                <a-button type="primary" @click="optionFun('ping')">屏显数据</a-button>
            </template>
            <!--配置-->
            <template v-if="scenario === 'shebaoList'">
                <a-button v-if="check_limit('shebao_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增社保配置</a-button>
            </template>
            <template v-else-if="scenario === 'zhufangList'">
                <a-button v-if="check_limit('zhufang_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增公积金配置</a-button>
            </template>
            <!--账单-->
            <template v-else-if="scenario === 'bsalaryList'">
                <a-button v-if="check_limit('bsalary_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增工资</a-button>
            </template>
            <template v-else-if="scenario === 'bsalaryDetail' ">
                <a-button v-if="check_limit('bsalary_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'bshebaoList'">
                <a-button v-if="check_limit('bshebao_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增社保</a-button>
            </template>
            <template v-else-if="scenario === 'bshebaoDetail'">
                <a-button v-if="check_limit('bsalary_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'bzhufangList'">
                <a-button v-if="check_limit('bzhufang_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增公积金</a-button>
            </template>
            <template v-else-if="scenario === 'bzhufangDetail'">
                <a-button v-if="check_limit('bzhufang_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'bbaoxianList'">
                <a-button v-if="check_limit('bbaoxian_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增商保</a-button>
            </template>
            <template v-else-if="scenario === 'bbaoxianDetail'">
                <a-button v-if="check_limit('bbaoxian_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'brefundList'">
                <a-button v-if="check_limit('brefund_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增返费</a-button>
            </template>
            <template v-else-if="scenario === 'brefundDetail'">
                <a-button v-if="check_limit('brefund_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'bmanageList'">
                <a-button v-if="check_limit('bmanage_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增管理费</a-button>
            </template>
            <template v-else-if="scenario === 'bmanageDetail'">
                <a-button v-if="check_limit('bmanage_detail_create')" type="primary" @click="optionFun('create')">补录管理费</a-button>
                <a-button v-if="check_limit('bmanage_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <template v-else-if="scenario === 'botherList'">
                <a-button v-if="check_limit('bother_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增开支</a-button>
            </template>
            <template v-else-if="scenario === 'botherDetail'">
                <a-button v-if="check_limit('bother_audit')" type="primary" @click="optionFun('audit')">审核通过</a-button>
            </template>
            <!--员工-->
            <template v-else-if="scenario === 'employeeList'">
                <a-button type="primary" @click="optionFun('caiji')">采集二维码</a-button>
                <a-button v-if="check_limit('employee_import')" type="primary" @click="optionFun('import')"><i class="iconfont icontop_import"></i>导入员工</a-button>
            </template>
            <!--人才-->
            <template v-else-if="scenario === 'rencaiMyList'">
                <a-button v-if="check_limit('rencai_import')" type="primary" @click="optionFun('import')"><i class="iconfont icontop_import"></i>导入人才</a-button>
                <a-button v-if="check_limit('rencai_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增人才</a-button>
            </template>
            <template v-else-if="scenario === 'rencaiEdit'">
                <a-button v-if="check_limit('rencai_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增人才</a-button>
                <a-button type="primary" @click="optionFun('visit')">回访</a-button>
                <a-button type="primary" @click="optionFun('interview')">安排</a-button>
            </template>
            <!--简历附件-->
            <template v-else-if="scenario === 'rcattachmentList'">
                <a-button v-if="check_limit('rcresume_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>上传简历</a-button>
            </template>
            <!--人才分析-->
            <template v-else-if="scenario === 'rcanalysisFenxi'">
                <a-button type="primary" @click="optionFun('fenxi2')">饼图分析</a-button>
            </template>
            <template v-else-if="scenario === 'rcanalysisFenxi2'">
                <a-button type="primary" @click="optionFun('fenxi')">拆线分析</a-button>
            </template>
            <!--企业-->
            <template v-else-if="scenario === 'companyList'">
                <a-button v-if="check_limit('company_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增企业</a-button>
            </template>
            <!--职位-->
            <template v-else-if="scenario === 'jobList'">
                <a-button v-if="check_limit('job_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增职位</a-button>
            </template>
            <template v-else-if="scenario === 'jobtplList'">
                <a-button v-if="check_limit('jobtpl_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增表单</a-button>
            </template>
            <!--员工合同-->
            <template v-else-if="scenario === 'empContractList'">
                <a-button v-if="check_limit('empcontract_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>续签合同</a-button>
            </template>
            <!--员工政策-->
            <template v-else-if="scenario === 'empPolicyList'">
                <a-button v-if="check_limit('emppolicy_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>上传政策</a-button>
            </template>
            <!--供应商-->
            <template v-else-if="scenario === 'supplierList'">
                <a-button v-if="check_limit('supplier_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增供应商</a-button>
            </template>
            <!--发布新闻-->
            <template v-else-if="scenario === 'newsList'">
                <a-button v-if="check_limit('news_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>发布新闻</a-button>
            </template>
            <!--创建分类-->
            <template v-else-if="scenario === 'newsClassList'">
                <a-button v-if="check_limit('news_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--新增广告-->
            <template v-else-if="scenario === 'adList'">
                <a-button v-if="check_limit('ad_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增广告</a-button>
            </template>
            <!--创建分类-->
            <template v-if="scenario === 'adClassList'">
                <a-button v-if="check_limit('ad_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--新增企业-->
            <template v-else-if="scenario === 'qiyeList'">
                <a-button v-if="check_limit('qiye_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增企业</a-button>
            </template>
            <!--创建企业分类-->
            <template v-if="scenario === 'qiyeClassList'">
                <a-button v-if="check_limit('qiye_class_create')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>创建分类</a-button>
            </template>
            <!--创建员工-->
            <template v-else-if="scenario === 'accountList'">
                <a-button v-if="check_limit('system_save_user')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增用户</a-button>
            </template>
            <!--创建角色-->
            <template v-else-if="scenario === 'roleList'">
                <a-button v-if="check_limit('system_save_role')" type="primary" @click="optionFun('create')"><i class="iconfont iconadd"></i>新增角色</a-button>
            </template>
            <!--退出登录-->
            <template v-else-if="scenario === 'center'">
                <a href="javascript:void(0);" class="login-out flex align-items" @click="optionFun('logout')"><i class="iconfont iconquit"></i>退出登录</a>
            </template>
        </div>
    </div>
</template>

<script>
import { Icon } from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
});
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        scenario: {
            type: [String],
            default: '',
        },
        postData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            checkAll: false,// 全选
            indeterminate: false,// 部分选中
        };
    },
    components: {
        IconFont,
    },
    methods: {
        // 操作
        optionFun(op) {
            this.$emit('optionFun', {scenario: this.scenario, op: op});
        },
    }
}
</script>
