import layout from '@/components/common/layout';
import analysisList from '../views/rcanalysis/list';
import analysisFenxi from '../views/rcanalysis/fenxi';
import analysisFenxi2 from '../views/rcanalysis/fenxi2';

export default [
    {
        path: '/rcanalysis',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "统计数据",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcanalysis_list',// 子选项
                    }
                },
                component: analysisList,
            },
            {
                path: 'fenxi',
                meta: {
                    metaInfo: {
                        title: "数据分析",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcanalysis_fenxi',// 子选项
                    }
                },
                component: analysisFenxi,
            },
            {
                path: 'fenxi2',
                meta: {
                    metaInfo: {
                        title: "数据分析",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '71',// 父选项
                        child: 'rcanalysis_fenxi2',// 子选项
                    }
                },
                component: analysisFenxi2,
            },
        ]
    },
];
