<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">

                    <a-form-item
                        label="客户经理"
                    >
                        <a-select
                            placeholder="请选择需要分配的客户经理"
                            v-decorator="['tomuid', {rules: [{ required: true, message: '请选择需要分配的客户经理' }]}]"
                        >
                            <a-select-option v-for="(item, key) in config.managers" :key="item[0]" :value="item[0]" :disabled="item[0] > 0 ? false : true">{{ item[1] }}</a-select-option>
                        </a-select>
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        rid: {
            type: Array,
            default: []
        },
        rcname: {
            type: Array,
            default: []
        },
    },
    data() {
        return {
            title: '回访人才',
            form: this.$form.createForm(this),
            formItemLayout: {
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 19
                },
            },
            config: {},
        };
    },
    watch: {
        // 实现双向绑定
        visible(val) {
            if (val === false) {
                this.form.resetFields(); // 重置表单
            } else {
                this.getData();
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible'
    },
    methods: {
        closeModal() {
            this.$emit('changeVisible', false);
        },
        getData() {
            if (this.rid) {

                this.title = '分配 ' + this.rcname.join(',');

                // 初始化底部宽度
                this.$nextTick(() => {
                    let data = {};
                    data.rid = this.rid;

                    this.http('/manage/manager/managers', data, (res) => {
                        this.config = res.config;
                    }, null, 1);

                });
            }
        },
        okFun() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/rencai/assign';
                        let data = values;
                        data.rid = this.rid;
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({content: '操作成功'}, () => {});
                            this.closeModal();
                            this.$emit("confirm");

                        }, null, 1);
                    }
                }
            );
        },
        cancelFun() {
            this.closeModal();
        },
    }
}
</script>
