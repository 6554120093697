<template>
    <a-modal
        :title="title"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form :form="form">
                        <a-form-item
                            label="安排类型"
                        >
                            <a-radio-group @change="changePlan" v-model="plan">
                                <a-radio key="0" value="interview">面试</a-radio>
                                <a-radio key="1" value="job">待入职</a-radio>
                            </a-radio-group>
                        </a-form-item>

                        <a-form-item
                            :label="tips + '企业'"
                        >
                            <a-auto-complete
                                class="global-search"
                                size="large"
                                style="width: 100%"
                                placeholder="请输入企业名称关键词"
                                option-label-prop="title"
                                @select="autoSelect"
                                @focus="autoSearch"
                                @search="autoSearch"
                                v-decorator="['cid', {rules: [{ required: true, message: '请选择需要安排' + tips + '的企业' }]}]"
                            >
                                <template slot="dataSource">
                                    <a-select-option v-if="dataSource.length < 1" key="0" style="font-size: normal">
                                        没有找到企业，去<a href="/company/create" target="_blank">新增企业</a>
                                    </a-select-option>
                                    <a-select-option v-else v-for="item in dataSource" :key="item.cid"
                                                     :title="item.cname">
                                        {{ item.cname }}
                                    </a-select-option>
                                </template>
                            </a-auto-complete>
                        </a-form-item>

                        <a-form-item
                            :label="tips + '时间'"
                        >
                            <a-date-picker
                                :placeholder="tips + '时间'"
                                v-decorator="['time', {rules: [{ required: true, message: '请选择' + tips + '时间' }]}]"
                            />
                        </a-form-item>

                        <a-form-item
                            label="备注"
                        >
                            <a-textarea
                                v-decorator="['viremark', {rules: [{ required: false, max: 128, message: '备注不超过128个字符' }], initialValue: ''}]"
                                placeholder="请填写回访备注"
                                :auto-size="{ minRows: 2 }"
                            />
                        </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        rid: {
            type: Array,
            default: [],
        },
        rcname: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            title: '安排面试',
            tips: '',
            plan: 'interview',
            form: this.$form.createForm(this),
            postData: {
                'keyword': '',
            },
            config: {},
            dataSource: {},
            data: {
                cid: 0,
            },
        }
    },
    watch: {
        // 实现双向绑定
        visible (val) {
            if (val === false) {
                this.form.resetFields() // 重置表单
            }
            else {
                this.getData()
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible',
    },
    methods: {
        changePlan (e) {
            if (e.target.value == 'job') {
                this.title = '安排入职 ' + this.rcname
                this.tips = '入职'
                this.plan = 'job'
            }
            else {
                this.title = '安排面试 ' + this.rcname
                this.tips = '面试'
                this.plan = 'interview'
            }
        },
        autoSearch (value) {
            if (value == '') {
                this.dataSource = {}
                return
            }

            this.postData.keyword = value

            this.http('/manage/company/search', this.postData, (res) => {

                this.dataSource = res.list

            }, null, 2)
        },
        autoSelect (value) {
            // console.log(value)
            this.data.cid = value
        },
        closeModal () {
            this.$emit('changeVisible', false)
        },
        getData () {
            if (this.rid.length > 0) {

                this.title = '安排面试 ' + this.rcname
                this.tips = '面试'
                this.plan = 'interview'
            }
        },
        okFun () {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = ''
                        let data = values
                        data.rid = this.rid
                        data.cid = this.data.cid

                        if (!this.data.cid) {
                            this.alert({ title: '请选择企业', content: '请选择需要安排' + this.tips + '的企业' })
                            return
                        }

                        if (this.plan == 'interview') {
                            url = '/manage/rcinterview/save'
                            data.interviewtime = data.time ? data.time.format('YYYY-MM-DD') : ''
                        }
                        else {
                            url = '/manage/rcjob/save'
                            data.jobtime = data.time ? data.time.format('YYYY-MM-DD') : ''
                        }
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({ content: '操作成功' }, () => {})
                            this.closeModal()
                            this.$emit('confirm')

                        }, null, 1)
                    }
                },
            )
        },
        cancelFun () {
            this.closeModal()
        },
    },
}
</script>
