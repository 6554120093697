import layout from '@/components/common/layout';
import stkdetailList from '../views/stkdetail/list';

export default [
    {
        path: '/stkdetail',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "开票列表",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '229',// 父选项
                        child: 'stkdetail_list',// 子选项
                    }
                },
                component: stkdetailList,
            },
        ]
    },
];
