<template>
  <a-modal
    title="会员续费"
    :visible="visible"
    class="modal-initialize"
    width="508px"
    @cancel="cancelFun"
    @ok="okFun"
  >
    <div class="bomb-remind">
      <div class="bomb-form">
        <div class="form-title">
          <h4>设置会员续费年限</h4>
        </div>
        <a-form class="inline-form" :form="form">
          <a-form-item label="年限" v-bind="formItemLayout">
            <a-input placeholder="请输入会员续费的年限" v-decorator="['years', {rules: [{ required: true, message: '请输入会员续费的年限' }]} ]" type="number"/>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </a-modal>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            acid: {
                type: [String, Number, Array],
                default: 0
            },
        },
        data() {
            return {
                showModal: this.visible,
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 5
                    },
                    wrapperCol: {
                        span: 19
                    },
                },
            };
        },
        watch: {
            // 实现双向绑定
            visible(val) {
                this.showModal = val;

                if (val === false) {
                    this.form.resetFields(); // 重置表单
                }
            },
            showModal(val) {
                this.$emit("changeModal", val);
            },
        },
        methods: {
            closeModal() {
                this.showModal = false;
            },
            okFun() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {
                            let data = {
                                years: values.years,
                            };
                            let url = '/manage/account/edit';
                            data.acid = this.acid;
                            //console.log(data);return;

                            this.http(url, data, (res) => {
                                this.toast({content: '设置成功'});
                                this.closeModal();
                                this.$emit("confirm");
                            }, null, 1);
                        }
                    }
                );
            },
            cancelFun() {
                this.closeModal();
            },
        }
    }
</script>
