import layout from '@/components/common/layout';
import bshebaoList from '../views/bshebao/list';
import bshebaoDetail from '../views/bshebao/detail';

export default [
    {
        path: '/bshebao',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部社保",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bshebao_list',// 子选项
                    }
                },
                component: bshebaoList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部社保明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'bshebao_list',// 子选项
                    }
                },
                component: bshebaoDetail,
            },
        ]
    },
];
