<template>
    <div class="home">
        <div class="home-head" :class="{'fixed-head': scrollTag}">
            <div class="middle-width flex align-items">
                <div class="home-logo flex1"><img src="../../assets/img/home-logo.png" alt=""></div>
                <div class="user-login flex align-items">
                    <a href="/manager/login" class="login-text">登录</a>
                    <a href="/manager/register" class="free-btn">免费体验</a>
                </div>
            </div>
        </div>
        <div class="home-content-column">
            <div class="middle-width">
                <div class="banner-text">
                    <div class="banner-title">
                        <h1>全流程人力资源外包<span>管理云平台</span></h1>
                        <p></p>
                    </div>
                    <h3 class="flex align-items">
                        <span>企业信息数据化</span>
                        <i class="doit"></i>
                        <span>在线管理流程化</span>
                        <i class="doit"></i>
                        <span>驻厂工作极简化</span>
                    </h3>
                    <div class="banner-btn">
                        <a href="/manager/register">免费体验<i class="iconfont iconarrow"></i></a>
                    </div>
                </div>
                <div class="company-type-column">
                    <div class="company-title">
                        <h2>助力企业人资转型</h2>
                        <p>从用户出发，为您提供更优的人力资源外包解决方案</p>
                    </div>
                    <div class="company-box flex">
                        <div class="company-type-list">
                            <span class="c-type-icon c-type01"></span>
                            <h3>提升工作效率  减少重复操作</h3>
                            <h4>你还在不断地用Excel表格管理数据吗？数据的正确率可以保证吗？</h4>
                        </div>
                        <div class="company-type-list">
                            <span class="c-type-icon c-type02"></span>
                            <h3>各环节无缝对接 实现管理价值</h3>
                            <h4>H5与PC端将组织、HR、员工无缝对接，为HR提供一站式解决方案</h4>
                        </div>
                        <div class="company-type-list">
                            <span class="c-type-icon c-type03"></span>
                            <h3>规避财务风险 优化收入结构</h3>
                            <h4>财务实时掌握回款进度，财务管理一目了然随心查询出入账信息</h4>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="home-content-column">
            <div class="prduct-intro-tab-column">
                <div class="middle-width">
                    <div class="company-type-column">
                        <div class="company-title">
                            <h2>产品功能</h2>
                        </div>
                        <div class="pro-tab-column">
                            <a-tabs defaultActiveKey="1" class="tabs-list">
                                <a-tab-pane key="1">
                                    <div slot="tab" class="tab-icon">
                                        <span class="tab-icon01"></span>
                                        <h3>企业管理</h3>
                                    </div>
                                    <div class="tab-content-column flex">
                                        <div class="tab-content-left">
                                            <h2>企业管理</h2>
                                            <p>代招岗位随时更新、合同到期及时提醒</p>
                                        </div>
                                        <div class="right-img">
                                            <img src="../../assets/img/legend_a.png" alt="">
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2">
                                    <div slot="tab" class="tab-icon">
                                        <span class="tab-icon02"></span>
                                        <h3>驻厂管理</h3>
                                    </div>
                                    <div class="tab-content-column flex">
                                        <div class="tab-content-left">
                                            <h2>驻厂管理</h2>
                                            <p>支持智能导入花名册、在线填写登记表，办理入职快人一步</p>
                                        </div>
                                        <div class="right-img">
                                            <img src="../../assets/img/legend_b.png" alt="">
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="3">
                                    <div slot="tab" class="tab-icon">
                                        <span class="tab-icon03"></span>
                                        <h3>员工管理</h3>
                                    </div>
                                    <div class="tab-content-column flex">
                                        <div class="tab-content-left">
                                            <h2>员工管理</h2>
                                            <p>电子化记录员工信息，实时展示员工概况</p>
                                        </div>
                                        <div class="right-img">
                                            <img src="../../assets/img/legend_c.png" alt="">
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="4">
                                    <div slot="tab" class="tab-icon">
                                        <span class="tab-icon04"></span>
                                        <h3>薪酬福利</h3>
                                    </div>
                                    <div class="tab-content-column flex">
                                        <div class="tab-content-left">
                                            <h2>薪酬福利</h2>
                                            <p>自定义薪酬结构，支持添加不同参保方案；一键生成打印工资单</p>
                                        </div>
                                        <div class="right-img">
                                            <img src="../../assets/img/legend_d.png" alt="">
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="5">
                                    <div slot="tab" class="tab-icon">
                                        <span class="tab-icon05"></span>
                                        <h3>财务管理</h3>
                                    </div>
                                    <div class="tab-content-column flex">
                                        <div class="tab-content-left">
                                            <h2>财务管理</h2>
                                            <p>出账入账清晰明了，对账管理一步到位，财务实现盈亏有账</p>
                                        </div>
                                        <div class="right-img">
                                            <img src="../../assets/img/legend_e.png" alt="">
                                        </div>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                </div>
                <div class="prduct-intro-tab-content-bg"></div>
            </div>
        </div>
        <div class="home-content-column">
            <div class="middle-width">
                <div class="quick-use">
                    <h3>我们为您提供</h3>
                    <p>一对一的专属顾问、一站式的配置服务、超快速的上线部署,<br>只为让您提升体验、快速入手！</p>
                    <a href="/manager/register" target="_blank">免费体验<i class="iconfont iconarrow"></i></a>
                </div>
            </div>
        </div>
        <div class="footer-column">
            <div class="logo-footer">
                <div class="middle-width flex align-items just-between">
                    <div class="footer-logo-img"><img src="../../assets/img/logo_bottom.png" alt=""></div>
                    <div class="code-footer flex">
                        <div class="code-list">
                            <div class="code-img"><img src="../../assets/img/gzh-code.png" alt=""></div>
                            <h3>微信公众号</h3>
                            <h3>联系电话：0551-62150169</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copy-right">
                <p>
              <span style="float:left;display: inline-block">
                  皖ICP备18007608号-6 <img src="../../assets/img/wangan.png" /> <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34019102000523" target="_blank"> 皖公网安备 34019102000523号</a>
              </span> Copyright &copy; 2022. All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            scrollTag: false,
        }
    },
    mounted(){
        let _this = this
        window.addEventListener('scroll',function(){
            let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset//要做兼容 在模拟器能正常获取scrolltop在微信h5页面和手机的浏览器页面一直为0
            if(top==0 || top < 58){
                _this.scrollTag=false;
            }else{
                _this.scrollTag=true;
            }
        })
    },
}
</script>

<style lang="less" src="../../assets/css/home/home.less">
</style>