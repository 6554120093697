<template>
    <a-table
        :columns="columns"
        :dataSource="dataSource"
        :rowKey="rowKey"
        :loading="{spinning: loading}"
        :pagination="pagination"
        :rowSelection="rowSelection"
        :locale="localeRes"
        :scroll="scroll ? scroll : {}"
        @change="change"
    >

        <span slot="remark" slot-scope="text, row, index" class="action-oper">
            <a-tooltip placement="bottomLeft" overlayClassName="draw_atooltip">
                <template slot="title">
                    <span>{{ row.remark }}</span>
                </template>
                <span style="display:block;width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
                    {{ text }}
                </span>
            </a-tooltip>
        </span>

        <!--配置-->
        <span slot="shebao_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">作废</a>
        </span>
        <span slot="zhufang_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">作废</a>
        </span>

        <!--对账单-->
        <span slot="bill_month" slot-scope="text, row, index" class="action-oper">
            <a-tooltip placement="bottomLeft" overlayClassName="draw_atooltip">
                <template slot="title" v-if="row.remark">
                    <span>{{ row.remark }}</span>
                </template>
                {{ text }}
            </a-tooltip>
        </span>

        <span slot="bill_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('create', row)" v-if="row.status == 1 && row.statement == 0">加入对账</a>
        </span>

        <span slot="statement_month" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/statement/detail?stid=' + row.stid)">{{ text }}</a>
        </span>
        <span slot="statement_kmoney" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/stkdetail/list?stid=' + row.stid)">{{ text }}</a>
        </span>
        <span slot="statement_smoney" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/stsdetail/list?stid=' + row.stid)">{{ text }}</a>
        </span>
        <span slot="statement_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('shoukuan', row)" v-if="row.status == 1">收款</a>
            <a href="javascript:;" @click="actionFun('kaipiao', row)" v-if="row.status == 1">开票</a>
        </span>

        <span slot="statement_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('unbind', row)" >解绑</a>
        </span>

        <span slot="stsdetail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" >作废</a>
        </span>

        <span slot="stkdetail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" >作废</a>
        </span>

        <!--账单-->
        <span slot="bsalary_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bsalary/detail?bsid=' + row.bsid)">{{ text }}</a>
        </span>
        <span slot="bsalary_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">上传</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bsalary_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="bshebao_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bshebao/detail?bsid=' + row.bsid)">{{ text }}</a>
        </span>
        <span slot="bshebao_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">生成</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bshebao_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('create', row)" v-if="row.status == 1">补缴</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="bzhufang_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bzhufang/detail?bzid=' + row.bzid)">{{ text }}</a>
        </span>
        <span slot="bzhufang_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">生成</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bzhufang_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('create', row)" v-if="row.status == 1">补缴</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="bbaoxian_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bbaoxian/detail?bbid=' + row.bbid)">{{ text }}</a>
        </span>
        <span slot="bbaoxian_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">上传</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bbaoxian_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="brefund_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/brefund/detail?brid=' + row.brid)">{{ text }}</a>
        </span>
        <span slot="brefund_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">上传</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="brefund_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="bmanage_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bmanage/detail?bmid=' + row.bmid)">{{ text }}</a>
        </span>
        <span slot="bmanage_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">生成</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bmanage_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <span slot="bother_num" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/bother/detail?boid=' + row.boid)">{{ text }}</a>
        </span>
        <span slot="bother_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('import', row)" v-if="row.status == 0 || row.status == 1">上传</a>
            <a href="javascript:;" @click="actionFun('audit', row)" v-if="row.status == 1">审核</a>
        </span>
        <span slot="bother_detail_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <!--待分配-->
        <span slot="rencai_unassign_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('rencai_claim')" href="javascript:;" @click="actionFun('claim', row)">认领</a>
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
        </span>

        <!--人才列表-->
        <span slot="rencai_rcname" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/rencai/edit?rid=' + row.rid)">{{ text }}</a>
            <a :href="'/rcattachment/list?rid=' + row.rid" v-if="row.attachment == 1" target="_blank"><i class="iconfont icon_attachment"></i></a>
        </span>
        <span slot="rencai_vistatus" slot-scope="text, row, index" class="action-oper">
            <a-tooltip placement="bottomLeft" overlayClassName="draw_atooltip">
                <template slot="title" v-if="row.viremark">
                    <span>{{ row.viremark }}</span>
                </template>
                {{ text }} <i class="iconfont icontips" v-if="row.viremark" style="font-size: 14px;"></i>
            </a-tooltip>
        </span>
        <span slot="rencai_mobile" slot-scope="text, row, index" class="action-oper">
            <a-tooltip placement="bottomLeft" overlayClassName="draw_atooltip">
                <template slot="title" v-if="row.wechat">
                    <span>微信：{{ row.wechat }}</span>
                </template>
                {{ text }} <i class="iconfont icontips" v-if="row.wechat" style="font-size: 14px;"></i>
            </a-tooltip>
        </span>
        <span slot="rencai_my_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('visit', row)">回访</a>
            <a href="javascript:;" @click="actionFun('interview', row)">安排</a>
        </span>

        <!--全部人才-->
        <span slot="rencai_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('rencai_assign')" href="javascript:;" @click="actionFun('assign', row)">分配</a>
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
        </span>

        <!--附件简历-->
        <span slot="rcattachment_action" slot-scope="text, row, index" class="action-oper">
            <a :href="row.attachmentUrl" target="_blank">下载</a>
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)">删除</a>
        </span>

        <!--回访-->
        <span slot="rcvisit_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)">删除</a>
        </span>

        <!--回访提醒-->
        <span slot="rcvisit_remind_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('visit', row)">回访</a>
            <a href="javascript:;" @click="actionFun('interview', row)">安排</a>
        </span>

        <!--面试-->
        <span slot="rcinterview_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('job', row)" v-if="row.status == 0">待入职</a>
            <a href="javascript:;" @click="actionFun('cancel', row)" v-if="row.status == 0">未通过</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 0">删除</a>
        </span>

        <!--入职-->
        <span slot="rcjob_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('checkin', row)" v-if="row.status == 0">登记</a>
            <a href="javascript:;" @click="actionFun('cancel', row)" v-if="row.status == 0">未通过</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 0">删除</a>
        </span>

        <!-- 数据分析 -->
        <span slot="rcanalysis_addnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('add', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="rcanalysis_visitnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('visit', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="rcanalysis_interviewnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('interview', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="rcanalysis_jobnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('job', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="rcanalysis_checkinnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('checkin', row)">{{ text == null ? 0 : text }}</a>
        </span>

        <!-- 企业 -->
        <span slot="company_jobnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('job', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="company_checkinnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('checkin', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="company_employeenum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('employee', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="company_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 职位 -->
        <span slot="job_checkinnum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('checkin', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="job_employeenum" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('employee', row)">{{ text == null ? 0 : text }}</a>
        </span>
        <span slot="job_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
            <a v-if="check_limit('employee_import')" href="javascript:;" @click="actionFun('import', row)">导员工</a>
            <a :href="row.ewm" target="_blank">登记码</a>
        </span>

        <span slot="jobtpl_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 员工登记 -->
        <span slot="employee_name" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionTo('/employee/edit?empid=' + row.empid)">{{ text }}</a>
        </span>
        <span slot="empcheckin_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('job', row)" v-if="row.status == 0">确认入职</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 0">未入职</a>
        </span>

        <!-- 员工 -->
        <span slot="employee_action" slot-scope="text, row, index" class="action-oper">
            <template v-if="row.bmid">
                <a href="javascript:;" @click="actionFun('manage', row)">补管理费</a>
            </template>
            <template v-else>
                <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            </template>
        </span>

        <!-- 员工合同 -->
        <span slot="empcontract_list_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('create', row)" v-if="row.status == 1">{{ row.id ? '续签' : '签合同'}}</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.id && row.status == 1">删除</a>
        </span>
        <span slot="empcontract_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>
        <!-- 员工政策 -->
        <span slot="emppolicy_policy" slot-scope="text, row, index"
              :title="text"
              style="display:block;width:300px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            {{ text }}
        </span>
        <span slot="emppolicy_list_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('create', row)" v-if="row.status == 1">{{ row.id ? '续签' : '新增政策'}}</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.id && row.status == 1">删除</a>
        </span>
        <span slot="emppolicy_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
        </span>

        <!-- 供应商 -->
        <span slot="supplier_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">删除</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-if="row.status == 9">恢复</a>
        </span>

        <!--广告列表-->
        <span slot="qiye_image" slot-scope="text, row, index" class="action-oper">
            <div
                style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img
                    :src="row.imageUrl" width="60"/></a>
            </div>
        </span>
        <span slot="qiye_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 广告分类 -->
        <span slot="qiye_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!--广告列表-->
        <span slot="ad_image" slot-scope="text, row, index" class="action-oper">
            <div
                style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img
                    :src="row.imageUrl" width="60"/></a>
            </div>
        </span>
        <span slot="ad_description" slot-scope="text, row, index"
              :title="text"
              style="display:block;width:300px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            {{ text }}
        </span>
        <span slot="ad_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 广告分类 -->
        <span slot="ad_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>


        <!--新闻列表-->
        <span slot="news_image" slot-scope="text, row, index" class="action-oper">
            <div
                style="width:60px;height: 60px;overflow: hidden;display: flex;justify-content:center;align-items:center;">
                <a :href="row.imageSrc" target="_blank" v-if="row.image" style="margin-left: 10px;"><img
                    :src="row.imageUrl" width="60"/></a>
            </div>
        </span>
        <span slot="news_title" slot-scope="text, row, index"
              :title="text"
              style="display:block;width:300px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"
        >
            <a-tag color="red" v-if="row.istop == 1">顶</a-tag>{{ text }}
        </span>
        <span slot="news_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('top', row)" v-if="row.istop == 0">置顶</a>
            <a href="javascript:;" @click="actionFun('untop', row)" v-else-if="row.istop == 1">取消置顶</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!-- 新闻分类 -->
        <span slot="news_class_action" slot-scope="text, row, index" class="action-oper">
            <a href="javascript:;" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:;" @click="actionFun('close', row)" v-if="row.status == 1">关闭</a>
            <a href="javascript:;" @click="actionFun('open', row)" v-else-if="row.status == 9">恢复</a>
        </span>

        <!--用户列表-->
        <span slot="account_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('system_user_info')" href="javascript:void(0);" @click="actionFun('edit', row)">编辑</a>
            <a href="javascript:void(0);" @click="actionFun('close', row)"
               v-if="check_limit('system_close_user') && row.status == 1">关闭</a>
            <a v-else-if="check_limit('system_open_user')" href="javascript:void(0);"
               @click="actionFun('open', row)">开启</a>
        </span>

        <!--角色列表-->
        <span slot="role_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('system_role_info')" href="javascript:void(0);" @click="actionFun('edit', row)">编辑</a>
            <a v-if="check_limit('system_delete_role')" href="javascript:void(0);"
               @click="actionFun('delete', row)">删除</a>
        </span>

        <!--主账户管理-->
        <span slot="act_action" slot-scope="text, row, index" class="action-oper">
            <a v-if="check_limit('account_edit')" href="javascript:void(0);" @click="actionFun('edit', row)">续费</a>
            <a v-if="check_limit('account_open') && row.status == 9" href="javascript:void(0);"
               @click="actionFun('open', row)">开启</a>
            <a v-if="check_limit('account_close') && row.status == 1" href="javascript:void(0);"
               @click="actionFun('close', row)">关闭</a>
        </span>


    </a-table>
</template>

<script>
export default {
    props: {
        // 字段
        columns: {
            type: Array,
            default: [],
        },
        // 数据
        dataSource: {
            type: [Object, Array],
            default: null,
        },
        // loading加载
        loading: {
            type: Boolean,
            default: false,
        },
        // 表格行 key 的取值
        rowKey: {
            type: String,
            default: '',
        },
        // 页码{ pageSize: pageSize, total: total, current: current, }
        pagination: {
            type: [Boolean, Object],
            default: false,
        },
        // 列表项是否可选择
        rowSelection: {
            type: [Object],
            default: null,
        },
        // 默认文案设置
        locale: {
            type: Object,
            default: null,
        },
        // 滚动条设置
        scroll: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            // indicator: <i class="anticon anticon-loading anticon-spin ant-spin-dot" style="font-size: 24px;"></i>,
            localeRes: {
                emptyText: '暂无数据',
            },
        }
    },
    methods: {
        // 分页回调
        change (e) {
            this.$emit('page', e)
        },
        actionFun (op, row) {
            row.op = op
            this.$emit('actionFun', row)
        },
        actionTo(url) {
            this.$router.push(url);
        }
    },
}
</script>

<style lang="less">
.action-oper {
    a {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}
//tips 控制主体颜色
.draw_atooltip .ant-tooltip-inner {
    color: #333 !important;
    background-color: #fff !important;
}
//控制三角形颜色
.draw_atooltip .ant-tooltip-arrow::before {
    background-color: #fff;
}
</style>
