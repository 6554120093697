<template>
    <a-modal
        title="修改手机号"
        :visible="visible"
        class="modal-initialize"
        width="508px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <!-- 修改社保 -->
            <div class="bomb-form">
                <!--<div class="form-title">
                    <h4>正在与<span>{{ cname }}</span>续签合同</h4>
                    <h5 class="red">注：若社保基数为0，则默认不缴纳社保！</h5>
                </div>-->
                <a-form class="inline-form" :form="form">
                    <a-form-item label="新手机号" v-bind="formItemLayout">
                        <a-input placeholder="请输入新手机号" v-decorator="['mobile', {rules: [{ required: true, len: 11, message: '请输入新手机号[11位]' }]} ]"/>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                showModal: this.visible,
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    },
                },
            };
        },
        watch: {
            // 实现双向绑定
            visible(val) {
                this.showModal = val;

                if (val === false) {
                    this.form.resetFields(); // 重置表单
                }
            },
            showModal(val) {
                this.$emit("changeModal", val);
            },
        },
        methods: {
            closeModal() {
                this.showModal = false;
            },
            okFun() {
                this.form.validateFields(
                    (errors, values) => {
                        if (!errors) {
                            this.http('/manage/manager/edit-mobile', values, (res) => {
                                this.toast({content: '修改成功'});
                                this.closeModal();
                                this.$emit("confirm");
                            }, null, 1);
                        }
                    }
                );
            },
            cancelFun() {
                this.closeModal();
            },
        }
    }
</script>
