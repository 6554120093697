<template>
    <div class="search-screen flex align-items">
        <div class="search-screen-left">
            <a-form layout="inline">

                <!--对账单-->
                <template v-if="scenario == 'shebaoList' || scenario == 'zhufangList'">
                    <a-form-item>
                        <a-input type="text" placeholder="版本名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--对账单-->
                <template v-if="scenario == 'statementList' || scenario == 'stsdetailList' || scenario == 'stkdetailList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-month-picker style="width:130px" placeholder="开始月" v-model="postData.smonth"/>
                        -
                        <a-month-picker style="width:130px" placeholder="结束月" v-model="postData.emonth"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--对账单-->
                <template v-if="scenario == 'billList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-month-picker style="width:130px" placeholder="开始月" v-model="postData.smonth"/>
                        -
                        <a-month-picker style="width:130px" placeholder="结束月" v-model="postData.emonth"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type" style="width:100px;">
                            <a-select-option value="">类型</a-select-option>
                            <a-select-option v-for="(item, key) in config.type" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--账单-->
                <template v-if="scenario == 'bsalaryList' || scenario == 'bshebaoList' || scenario == 'bzhufangList' || scenario == 'bbaoxianList' || scenario == 'brefundList' || scenario == 'bmanageList' || scenario == 'botherList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-month-picker style="width:130px" placeholder="开始月" v-model="postData.smonth"/>
                        -
                        <a-month-picker style="width:130px" placeholder="结束月" v-model="postData.emonth"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <template v-if="scenario == 'bsalaryDetail' || scenario == 'bshebaoDetail' || scenario == 'bzhufangDetail' || scenario == 'bbaoxianDetail' || scenario == 'brefundDetail' || scenario == 'bmanageDetail' || scenario == 'botherDetail'">
                    <a-form-item>
                        <a-input type="text" placeholder="员工姓名" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--人才-->
                <template
                    v-if="scenario == 'rencaiUnassignList' || scenario == 'rencaiMyList' || scenario == 'rencaiList'">
                    <a-form-item style="margin-right: 0;">
                        <a-input type="text" placeholder="关键词" v-model="postData.keyword" style="width: 120px;"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type" style="width:100px;">
                            <a-select-option key="0" value="rcname">姓名</a-select-option>
                            <a-select-option key="1" value="hopejob">求职意向</a-select-option>
                            <a-select-option key="2" value="mobile">手机</a-select-option>
                            <a-select-option key="3" value="viremark">回访内容</a-select-option>
                            <a-select-option key="4" value="supplier">供应商</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-input-number placeholder="年龄" v-model="postData.ages" :min="0" :max="100" style="width: 60px;"/>
                        -
                        <a-input-number placeholder="年龄" v-model="postData.agee" :min="0" :max="100" style="width: 60px;"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.degree" style="width: 100px;">
                            <a-select-option value="">学历</a-select-option>
                            <a-select-option v-for="(item, key) in config.degree" :key="key" :value="key">{{
                                    item
                                }}及以上
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.sex">
                            <a-select-option value="">性别</a-select-option>
                            <a-select-option v-for="(item, key) in config.sex" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.vistatus" style="width:100px;">
                            <a-select-option value="">回访状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.vistatus" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--回访-->
                <template v-if="scenario == 'rcvisitList' || scenario == 'rcvisitRemindList'">
                    <a-form-item style="margin-right: 0;">
                        <a-input type="text" placeholder="关键词" v-model="postData.keyword" style="width: 120px;"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option key="0" value="viremark">回访内容</a-select-option>
                            <a-select-option key="1" value="rcname">姓名</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="回访开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="回访结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.vistatus" style="width:100px;">
                            <a-select-option value="">回访状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.vistatus" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--面试-->
                <template v-if="scenario == 'rcinterviewList' || scenario == 'rcinterviewRemindList'">
                    <a-form-item style="margin-right: 0;">
                        <a-input type="text" placeholder="名称关键词" v-model="postData.keyword" style="width: 140px;"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option key="0" value="rcname">人才姓名</a-select-option>
                            <a-select-option key="1" value="cname">企业名称</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:100px" placeholder="面试开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:100px" placeholder="面试结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">面试状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item v-if="scenario == 'rcinterviewList'">
                        <a-select v-model="postData.smuid" style="width:100px;">
                            <a-select-option value="">客户经理</a-select-option>
                            <a-select-option v-for="(item, key) in config.managers" :key="item[0]" :value="item[0]" :disabled="item[0] > 0 ? false : true">{{ item[1] }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--入职-->
                <template v-if="scenario == 'rcjobList' || scenario == 'rcjobRemindList'">
                    <a-form-item style="margin-right: 0;">
                        <a-input type="text" placeholder="名称关键词" v-model="postData.keyword" style="width: 140px;"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option key="0" value="rcname">人才姓名</a-select-option>
                            <a-select-option key="1" value="cname">企业名称</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:100px" placeholder="入职开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:100px" placeholder="入职结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status" style="width:100px;">
                            <a-select-option value="">入职状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item v-if="scenario == 'rcjobList'">
                        <a-select v-model="postData.smuid" style="width:100px;">
                            <a-select-option value="">客户经理</a-select-option>
                            <a-select-option v-for="(item, key) in config.managers" :key="item[0]" :value="item[0]" :disabled="item[0] > 0 ? false : true">{{ item[1] }}</a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--数据分析-->
                <template v-if="scenario == 'rcanalysisList' || scenario == 'rcanalysisFenxi2'">
                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="开始日期" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="结束日期" v-model="postData.edate"/>
                    </a-form-item>
                </template>

                <!--数据分析-->
                <template v-if="scenario == 'rcanalysisFenxi'">
                    <a-form-item>
                        <a-month-picker style="width:130px" placeholder="选择月份" v-model="postData.date"/>
                    </a-form-item>
                </template>

                <!--企业-->
                <template v-if="scenario == 'companyList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--职位-->
                <template v-if="scenario == 'jobList'">
                    <a-form-item>
                        <a-input type="text" placeholder="职位名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <template v-if="scenario == 'jobtplList'">
                    <a-form-item>
                        <a-input type="text" placeholder="表单名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--员工登记-->
                <template v-if="scenario == 'empCheckinList'">
                    <a-form-item style="margin-right:0px;">
                        <a-input type="text" placeholder="关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option key="0" value="employee">姓名</a-select-option>
                            <a-select-option key="1" value="company">企业</a-select-option>
                            <a-select-option key="2" value="mobile">手机</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">处理状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>
                <!--员工-->
                <template v-if="scenario == 'employeeList'">
                    <a-form-item style="margin-right: 0;">
                        <a-input type="text" placeholder="名称关键词" v-model="postData.keyword" style="width: 140px;"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.type">
                            <a-select-option key="0" value="empname">员工姓名</a-select-option>
                            <a-select-option key="1" value="cname">企业名称</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:120px" placeholder="入职开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:120px" placeholder="入职结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>
                <!--员工合同-->
                <template v-if="scenario == 'empContractList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="合同到期开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="合同到期结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>
                <!--员工合同提醒-->
                <template v-if="scenario == 'empContractRemindList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="合同到期开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="合同到期结束" v-model="postData.edate"/>
                    </a-form-item>
                </template>
                <!--员工政策-->
                <template v-if="scenario == 'empPolicyList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="政策到期开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="政策到期结束" v-model="postData.edate"/>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>
                <!--员工政策提醒-->
                <template v-if="scenario == 'empPolicyRemindList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="政策到期开始" v-model="postData.sdate"/>
                        -
                        <a-date-picker style="width:130px" placeholder="政策到期结束" v-model="postData.edate"/>
                    </a-form-item>
                </template>

                <!--供应商-->
                <template v-if="scenario == 'supplierList'">
                    <a-form-item>
                        <a-input type="text" placeholder="供应商关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">
                                {{ item }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告管理-->
                <template v-if="scenario == 'adList'">
                    <a-form-item>
                        <a-input type="text" placeholder="广告描述关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.adcid">
                            <a-select-option value="">广告分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.adcid" :key="key" :value="key">{{
                                    item.adcname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告分类管理-->
                <template v-if="scenario == 'adClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="广告分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--企业管理-->
                <template v-if="scenario == 'qiyeList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业名称关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.qcid">
                            <a-select-option value="">企业分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.qcid" :key="key" :value="key">{{
                                    item.qcname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--广告分类管理-->
                <template v-if="scenario == 'qiyeClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--新闻管理-->
                <template v-if="scenario == 'newsList'">
                    <a-form-item>
                        <a-input type="text" placeholder="新闻标题关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.ncid">
                            <a-select-option value="">新闻分类</a-select-option>
                            <a-select-option v-for="(item, key) in config.ncid" :key="key" :value="key">{{
                                    item.ncname
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.istop">
                            <a-select-option value="">置顶</a-select-option>
                            <a-select-option v-for="(item, key) in config.tops" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--新闻分类管理-->
                <template v-if="scenario == 'newsClassList'">
                    <a-form-item>
                        <a-input type="text" placeholder="新闻分类关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <!--主账户管理-->
                <template v-if="scenario == 'AccountManage'">
                    <a-form-item>
                        <a-input type="text" placeholder="企业名称" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.viptype">
                            <a-select-option value="">会员类型</a-select-option>
                            <a-select-option v-for="(item, key) in config.viptype" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-date-picker style="width:130px" placeholder="会员到期日期" v-model="postData.date"/>
                    </a-form-item>
                </template>

                <!--用户管理-->
                <template v-if="scenario == 'accountList'">
                    <a-form-item>
                        <a-input type="text" placeholder="姓名关键词" v-model="postData.keyword"></a-input>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.status">
                            <a-select-option value="">状态</a-select-option>
                            <a-select-option v-for="(item, key) in config.status" :key="key" :value="key">{{
                                    item
                                }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item>
                        <a-select v-model="postData.roleid">
                            <a-select-option value="">角色名称</a-select-option>
                            <a-select-option v-for="(item, key) in config.roleid" :key="item.roleid"
                                             :value="item.roleid">{{ item.rolename }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </template>

                <a-form-item>
                    <a-button-group>
                        <a-button @click="clear">重置</a-button>
                        <a-button type="primary" @click="search">搜索</a-button>
                    </a-button-group>
                </a-form-item>

                <a-form-item v-if="showTagSearch">
                    <a-button type="primary" class="red-btn" @click="showModalTag = true">标签搜索</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="search-screen-right">
            <a href="javascript:void(0);" v-if="showExport" @click="exportRes">
                <icon-font type="icontool_export"/>
                导出结果
            </a>
            <a href="javascript:void(0);" @click="showModal()" v-if="showSetCondition">
                <icon-font type="icontool_set"/>
                显示设置
            </a>
        </div>

        <!-- 弹框  -->
        <a-modal
            okText="确定"
            cancelText="取消"
            v-model="visible"
            @ok="handleOk"
            @cancel="closeSetModal"
            :closable=false
            class="modal-initialize"
            width="700px"
            v-if="showSetCondition"
        >
            <template slot="title">
                <div class="modal-title flex">
                    <h4>显示设置</h4>
                    <icon-font type="iconclose" @click="closeSetModal"/>
                </div>
            </template>
            <div class="display-modal-body">
                <div class="choose-pageno hro-modal-title">
                    <h4>每页显示数量</h4>
                    <div class="pageno-btn-list">
                        <a-button
                            type="primary"
                            size="small"
                            v-for="(item, key) in setCondition.pagesize"
                            :key="key"
                            :class="{'grey-btn': parseInt(item) != modalData.pagesize}"
                            @click="modalData.pagesize = parseInt(item)">
                            {{ item }}
                        </a-button>
                    </div>
                </div>
                <div class="choose-table-title hro-modal-title">
                    <h4>选择列表显示的标题信息</h4>
                    <div class="title-groups">
                        <a-checkbox-group @change="onChange" :value="modalData.showfield">
                            <a-row>
                                <a-col
                                    :span="6"
                                    v-for="(item, key) in setCondition.field"
                                    :key="key"
                                >
                                    <a-checkbox :value="item.key" :disabled="item.default == 1">{{
                                            item.text
                                        }}
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </div>
                </div>
            </div>
        </a-modal>

        <!--创建分类-->
        <search-tag
            v-model="showModalTag"
            :checkData="checkData"
            @search="tagSearch"
        >
        </search-tag>
    </div>
</template>
<script>
import moment from 'moment'
import { Icon } from 'ant-design-vue'
import searchTag from '@/components/modal/searchTag';

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: process.env.VUE_APP_ICONURL,
})

export default {
    components: {
        IconFont,
        searchTag
    },
    props: {
        // 条件
        condition: {
            type: Object,
            default: null,
        },
        // 选中数据
        checkData: {
            type: Object,
            default: null,
        },
        // 显示设置条件
        setCondition: {
            type: Object,
            default: null,
        },
        // 配置
        config: {
            type: Object,
            default: null,
        },
        // 场景
        scenario: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            formatYear: 'YYYY',
            modeYear: 'year',
            visible: false,
            showModalSearch: false,
            formItemLayout: {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            postData: {},
            modalData: {
                pagesize: 0,
                showfield: [],
            },
            dateFormat: 'YYYY-MM-DD',
            showModalTag: false, // 标签
        }
    },
    computed: {
        // 高级搜索
        showTagSearch() {
            let scenarioArr = ['rencaiList', 'rencaiUnassignList', 'rencaiMyList'];
            if(this.inArray(this.scenario, scenarioArr) > -1) {
                return true;
            } else {
                return false;
            }
        },
        // 导出
        showExport () {
            let scenarioArr = ['rcinterviewList', 'rcjobList', 'rcanalysisList']
            if (this.inArray(this.scenario, scenarioArr) > -1) {
                return true
            }
            else {
                return false
            }
        },
        // 显示设置
        showSetCondition () {
            return true
            /*
            let scenarioArr = ['myProjectList', 'projectList', 'visitList', 'assignList', 'myClassList', 'classList'];
            if(this.inArray(this.scenario, scenarioArr) > -1) {
                return true;
            } else {
                return false;
            }
            */
        },
    },
    watch: {
        checkData () {
            // 筛选数据
            this.initConditionData()
        },
        setCondition () {
            this.initModalData()
        },
    },
    created () {
        this.initConditionData()
    },
    methods: {
        // 导出结果
        exportRes () {
            this.$emit('exportRes')
        },
        // 显示设置
        showModal () {
            this.visible = true
        },
        handleOk () {
            this.visible = false
            this.$emit('setOption', this.modalData)
        },
        closeSetModal () {
            this.visible = false
            this.initModalData()
        },
        // 开始日期禁用
        disabledStartDate (startValue) {
            const endValue = this.postData.eupdatetime
            if (!startValue || !endValue) {
                return false
            }
            return startValue.valueOf() > endValue.valueOf()
        },
        // 结束日期禁用
        disabledEndDate (endValue) {
            const startValue = this.postData.supdatetime
            if (!endValue || !startValue) {
                return false
            }
            return startValue.valueOf() >= endValue.valueOf()
        },
        // 初始化
        initModalData () {
            // 弹框数据
            let field = []
            for (let i in this.setCondition.field) {
                if (this.setCondition.field[i].default == 1) {
                    field.push(this.setCondition.field[i].key)
                }
            }

            this.modalData = {
                pagesize: this.checkData.pagesize,
                showfield: this.checkData.showfield && this.checkData.showfield.length > 0
                    ? this.checkData.showfield
                    : field,
            }
        },
        onChange (checkedValues) {
            this.modalData.showfield = checkedValues
        },
        // 重置筛选
        clear () {
            this.$emit('clear')
        },
        // 筛选
        search () {
            let params = {}
            for (var i in this.postData) {
                params[i] = this.postData[i]
            }

            // 处理时间等需要定制的搜索项
            switch (this.scenario) {
                case 'rcvisitList':
                case 'rcvisitRemindList':
                case 'rcinterviewList':
                case 'rcinterviewRemindList':
                case 'rcjobList':
                case 'rcjobRemindList':
                case 'empContractList':
                case 'empContractRemindList':
                case 'empPolicyList':
                case 'empPolicyRemindList':
                case 'rcanalysisList':
                case 'rcanalysisFenxi2':
                case 'employeeList':
                    params.sdate = params.sdate ? params.sdate.format('YYYY-MM-DD') : ''
                    params.edate = params.edate ? params.edate.format('YYYY-MM-DD') : ''
                    break
                case 'rcanalysisFenxi':
                    params.date = params.date ? params.date.format('YYYY-MM') : ''
                    break
                case 'bsalaryList':
                case 'bshebaoList':
                case 'bzhufangList':
                case 'bbaoxianList':
                case 'brefundList':
                case 'bmanageList':
                case 'botherList':
                case 'billList':
                case 'statementList':
                case 'stsdetailList':
                case 'stkdetailList':
                    params.smonth = params.smonth ? params.smonth.format('YYYY-MM') : ''
                    params.emonth = params.emonth ? params.emonth.format('YYYY-MM') : ''
                    break
                default:
                    break
            }

            this.$emit('search', params)
        },
        // 标签搜索
        tagSearch(param) {
            this.$emit('search', param);
        },
        initConditionData () {
            let params = {}
            for (var i in this.checkData) {
                params[i] = this.checkData[i]
            }

            // 处理时间等需要定制的搜索项
            switch (this.scenario) {
                case 'rcvisitList':
                case 'rcvisitRemindList':
                case 'rcinterviewList':
                case 'rcinterviewRemindList':
                case 'rcjobList':
                case 'rcjobRemindList':
                case 'empContractList':
                case 'empContractRemindList':
                case 'empPolicyList':
                case 'empPolicyRemindList':
                case 'rcanalysisList':
                case 'rcanalysisFenxi2':
                case 'employeeList':
                    params.sdate = params.sdate ? moment(params.sdate) : null
                    params.edate = params.edate ? moment(params.edate) : null
                    break
                case 'rcanalysisFenxi':
                    params.date = params.date ? moment(params.date) : null
                    break
                case 'bsalaryList':
                case 'bshebaoList':
                case 'bzhufangList':
                case 'bbaoxianList':
                case 'brefundList':
                case 'bmanageList':
                case 'botherList':
                case 'billList':
                case 'statementList':
                case 'stsdetailList':
                case 'stkdetailList':
                    params.smonth = params.smonth ? moment(params.smonth) : null
                    params.emonth = params.emonth ? moment(params.emonth) : null
                    break
                default:
                    break
            }

            this.postData = params
            this.postData.page = 1
        },
        changeModal (e, type) {
            switch (type) {
                case 1:
                    this.showModalSearch = e
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="less">
.search-screen {
    justify-content: space-between;
    padding-bottom: 30px;

    .search-screen-right {
        a {
            margin-left: 26px;

            i {
                margin-right: 8px;
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .search-screen-left {
        .ant-input {
            width: 120px;
        }
    }
}
</style>
