import layout from '@/components/common/layout';
import companyList from '../views/company/list';
import companyCreate from '../views/company/create';
import companyEdit from '../views/company/edit';

export default [
    {
        path: '/company',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部企业",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'company_list',// 子选项
                    }
                },
                component: companyList,
            },
            {
                path: 'create',
                meta: {
                    metaInfo: {
                        title: "新增企业",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'company_list',// 子选项
                    }
                },
                component: companyCreate,
            },
            {
                path: 'edit',
                meta: {
                    metaInfo: {
                        title: "编辑企业",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '113',// 父选项
                        child: 'company_list',// 子选项
                    }
                },
                component: companyEdit,
            },
        ]
    },
];
