<template>
    <a-modal
        title="人才标签"
        :visible="visible"
        class="modal-initialize"
        width="600px"
        @cancel="cancelFun"
        @ok="okFun"
    >
        <div class="bomb-remind">
            <div class="bomb-form">
                <a-form class="inline-form" :form="form">

                    <a-form-item
                        label="标签名"
                    >
                        <a-input placeholder="多个标签，请用逗号（,）分隔"
                                 v-decorator="['tagname', {rules: [{ required: true, max: 1024, message: '请输入人才标签，最大1024字符' }], initialValue: ''}]"/>


                        <a-tag v-for="(item, key) in tags" :key="item.tagid" @click="() => tagAdd(item.tagname)">{{ item.tagname }}</a-tag>
                    </a-form-item>

                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        rid: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            form: this.$form.createForm(this),
            formItemLayout: {
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 19
                },
            },
            tags: {},
        };
    },
    watch: {
        // 实现双向绑定
        visible(val) {
            if (val === false) {
                this.form.resetFields(); // 重置表单
            } else {
                this.getData();
            }
        },
    },
    model: {
        prop: 'visible',
        // 这个事件名可以随意写，它实际上是规定了子组件要更新父组件值需要注册的方法
        event: 'changeVisible'
    },
    methods: {
        tagAdd (tagname) {
            let tags = [];

            let tag = this.form.getFieldValue('tagname');
            if (tag) {
                tags.push(tag);
            }

            tags.push(tagname);

            this.form.setFieldsValue({
                tagname: tags.join(','),
            })
        },
        closeModal() {
            this.$emit('changeVisible', false);
        },
        getData() {
            if (this.rid) {
                // 初始化底部宽度
                this.$nextTick(() => {
                    let data = {}
                    data.rid = this.rid

                    this.http('/manage/rctag/list', data, (res) => {
                        this.tags = res.list;

                    }, null, 1)

                })
            }
        },
        okFun() {
            this.form.validateFields(
                (errors, values) => {
                    if (!errors) {

                        let url = '/manage/rctag/save';
                        let data = values;
                        data.rid = this.rid;
                        //console.log(data);return;

                        this.http(url, data, (res) => {
                            this.toast({content: '操作成功'});
                            this.closeModal();
                            this.$emit("confirm");
                        }, null, 1);
                    }
                }
            );
        },
        cancelFun() {
            this.closeModal();
        },
    }
}
</script>
