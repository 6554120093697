import layout from '@/components/common/layout';
import brefundList from '../views/brefund/list';
import brefundDetail from '../views/brefund/detail';

export default [
    {
        path: '/brefund',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部返费",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'brefund_list',// 子选项
                    }
                },
                component: brefundList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部返费明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '170',// 父选项
                        child: 'brefund_list',// 子选项
                    }
                },
                component: brefundDetail,
            },
        ]
    },
];
