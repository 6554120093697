import layout from '@/components/common/layout';
import statementList from '../views/statement/list';
import statementDetail from '../views/statement/detail';

export default [
    {
        path: '/statement',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "全部对账单",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '229',// 父选项
                        child: 'statement_list',// 子选项
                    }
                },
                component: statementList,
            },
            {
                path: 'detail',
                meta: {
                    metaInfo: {
                        title: "全部对账单明细",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '229',// 父选项
                        child: 'statement_list',// 子选项
                    }
                },
                component: statementDetail,
            },
        ]
    },
];
