import home from '../views/home/index';

export default [
    {
        path: '/',
        meta: {
            metaInfo: {
                title: "HelloHRO人资源外包管理云平台",
                keywords: "hro,hellohro,人力资源,人力资源外包管理,人资源外包管理云平台",
                description: "HelloHRO是爱沃信息科技旗下一款专门为人力资源外包公司提供全流程人力资源外包管理的一款云平台软件"
            },
        },
        component: home
    },
]