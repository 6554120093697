import layout from '@/components/common/layout';
import newsList from '../views/news/list';
import newsCreate from '../views/news/create';
import newsListClass from '../views/news/list-class';

export default [
  {
    path: '/news',
    component: layout,
    children: [
      {
        path: 'list',
        meta: {
          metaInfo: {
            title: "新闻管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'news_list',// 子选项
          }
        },
        component: newsList,
      },
      {
        path: 'create',
        meta: {
          metaInfo: {
            title: "发布新闻",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'news_info',// 子选项
          }
        },
        component: newsCreate,
      },
      {
        path: 'list-class',
        meta: {
          metaInfo: {
            title: "分类管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '22',// 父选项
            child: 'news_class_list',// 子选项
          }
        },
        component: newsListClass,
      },
    ]
  },
];
