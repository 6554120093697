import layout from '@/components/common/layout';
import adList from '../views/ad/list';
import adCLassList from '../views/ad/list-class';
import adCreate from '../views/ad/create';

export default [
  {
    path: '/ad',
    component: layout,
    children: [
      {
        path: 'list',
        meta: {
          metaInfo: {
            title: "广告管理",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '33',// 父选项
            child: 'ad_list',// 子选项
          }
        },
        component: adList,
      },
      {
        path: 'list-class',
        meta: {
          metaInfo: {
            title: "广告分类",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '33',// 父选项
            child: 'ad_class_list',// 子选项
          }
        },
        component: adCLassList,
      },
      {
        path: 'create',
        meta: {
          metaInfo: {
            title: "新增广告",
            keywords: "",
            description: ""
          },
          requireLogin: true,
          menu: {
            parent: '33',// 父选项
            child: 'ad_list',// 子选项
          }
        },
        component: adCreate,
      }
    ]
  },
];
