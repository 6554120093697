import layout from '@/components/common/layout';
import zhufangList from '../views/zhufang/list';

export default [
    {
        path: '/zhufang',
        component: layout,
        children: [
            {
                path: 'list',
                meta: {
                    metaInfo: {
                        title: "社保配置",
                        keywords: "",
                        description: ""
                    },
                    requireLogin: true,
                    menu: {
                        parent: '1',// 父选项
                        child: 'zhufang_list',// 子选项
                    }
                },
                component: zhufangList,
            },
        ]
    },
];
